import { color, size } from './primitives'

export const formControlBgColorActive = color.semanticBlue[70]
export const formControlBgColorDefault = color.semanticWhite[100]
export const formControlBgColorError = color.semanticCoolRed[60]
export const formControlBgColorHover = color.semanticBlue[60]
export const formControlBgColorSelected = color.semanticBlue[50]
export const formControlBgColorSelectedActive = color.semanticBlue[70]
export const formControlBorderColorActive = color.semanticNeutral[100]
export const formControlBorderColorDefault = color.semanticNeutral.fade[65]
export const formControlBorderColorError = color.semanticCoolRed[60]
export const formControlBorderColorHover = color.semanticNeutral.fade[90]
export const formControlBorderColorSelected = color.semanticBlue[50]
export const formControlBorderColorSelectedActive = color.semanticBlue[70]
export const formControlBorderColorSelectedHover = color.semanticBlue[60]
export const formControlBorderColorUnselectedActive = color.semanticNeutral[100]
export const formControlHighlightBgColorDefault = color.semanticNeutral.fade[10]
export const formControlThumbBgColorActive = color.semanticBlue[70]
export const formControlThumbBgColorDefault = color.semanticNeutral[100]
export const formControlThumbBgColorHover = color.semanticBlue[60]
export const formElementInputBgColorDefault = color.semanticWhite[100]
export const formElementInputBgColorDisabled = color.semanticNeutral.fade[15]
export const formElementInputBgColorError = color.semanticCoolRed[60]
export const formElementInputBgColorRead = color.semanticNeutral.fade[5]
export const formElementInputBorderColor = color.semanticNeutral.fade[65]
export const formElementInputBorderColorActive = color.semanticBlue[50]
export const formElementInputBorderColorError = color.semanticCoolRed[60]
export const formElementInputBorderColorHover = color.semanticNeutral.fade[90]
export const formElementInputBorderWidth = size[10]
export const formElementInputBorderWidthActive = size[20]
export const formElementTextColorDisabled = color.semanticNeutral.fade[45]
export const formElementTextColorRequired = color.semanticCoolRed[60]

export const inkFormTokens = {
  formControlBgColorActive,
  formControlBgColorDefault,
  formControlBgColorError,
  formControlBgColorHover,
  formControlBgColorSelected,
  formControlBgColorSelectedActive,
  formControlBorderColorActive,
  formControlBorderColorDefault,
  formControlBorderColorError,
  formControlBorderColorHover,
  formControlBorderColorSelected,
  formControlBorderColorSelectedActive,
  formControlBorderColorSelectedHover,
  formControlBorderColorUnselectedActive,
  formControlHighlightBgColorDefault,
  formControlThumbBgColorActive,
  formControlThumbBgColorDefault,
  formControlThumbBgColorHover,
  formElementInputBgColorDefault,
  formElementInputBgColorDisabled,
  formElementInputBgColorError,
  formElementInputBgColorRead,
  formElementInputBorderColor,
  formElementInputBorderColorActive,
  formElementInputBorderColorError,
  formElementInputBorderColorHover,
  formElementInputBorderWidth,
  formElementInputBorderWidthActive,
  formElementTextColorDisabled,
  formElementTextColorRequired,
} as const
