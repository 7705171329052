import { inkFontTokens } from '@ds/tokens'

const tokens = {
  defaultColor: inkFontTokens.fontColorLink,
  defaultHoverColor: inkFontTokens.fontColorLinkHover,
  defaultActiveColor: inkFontTokens.fontColorLinkActive,
  defaultVisitedColor: inkFontTokens.fontColorLinkVisited,
  subtleColor: inkFontTokens.fontColorLinkSubtle,
  subtleHoverColor: '#005cd3',
  subtleActiveColor: '#01439c',
  subtleVisitedColor: 'rgba(25, 24, 35, 0.65)',
  lightColor: '#0069ec', // adding for parity with Olive Tokens, not in use
  lightHoverColor: '#005cd3',
} as const

export default tokens
