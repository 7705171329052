import { oliveBarTokens } from '@ds/tokens'

export default () => {
  return {
    default: {
      circleFill: {
        stroke: '#666666',
      },

      circleTrack: {
        stroke: oliveBarTokens.barTrackColorDefault,
      },
    },

    subtle: {
      circleFill: {
        stroke: oliveBarTokens.barFillColorSubtle,
      },
    },
  } as const
}
