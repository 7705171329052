import { color, size } from './primitives'

/** @deprecated Use combined focus tokens `focusInner` or `focusOuter` instead. */
export const focusColor = color.semanticNeutral.fade[65]
/** @deprecated Use combined focus tokens `focusInner` or `focusOuter` instead. */
export const focusInset = '-4px'
/** @deprecated Use combined focus tokens `focusInner` or `focusOuter` instead. */
export const focusOutsetS = size[20]
/** @deprecated Use combined focus tokens `focusInner` or `focusOuter` instead. */
export const focusOutsetM = size[30]
/** @deprecated Use combined focus tokens `focusInner` or `focusOuter` instead. */
export const focusOutsetL = size[40]
/** @deprecated Use combined focus tokens `focusInner` or `focusOuter` instead. */
export const focusWidth = size[20]

export const focusInner = {
  outline: `${size[20]} solid ${color.semanticNeutral.fade[65]}`,
  outlineOffset: `-${size[30]}`,
}
export const focusOuter = {
  outline: `${size[20]} solid ${color.semanticNeutral.fade[65]}`,
  outlineOffset: size[20],
}

export const inkFocusTokens = {
  focusColor,
  focusInner,
  focusInset,
  focusOuter,
  focusOutsetS,
  focusOutsetM,
  focusOutsetL,
  focusWidth,
} as const
