const shadow = {
  1: '0px 2px 2px rgba(0, 0, 0, 0.15)',
  '1R': '0px 2px 2px rgba(0, 0, 0, 0.15)',
  4: '0px 2px 4px rgba(0, 0, 0, 0.15)',
  '4R': '0px 2px 4px rgba(0, 0, 0, 0.15)',
  8: '0px 2px 4px rgba(0, 0, 0, 0.15)',
  16: '0px 2px 4px rgba(0, 0, 0, 0.15)',
  32: '0px 2px 7px rgba(0, 0, 0, 0.25)',
} as const

export default shadow
