import { inkMessageTokens } from '@ds/tokens'
import type { InlineMessageKind } from '../../../../components/InlineMessage'
import colors from '../colors'

export interface InlineMessageTokensInk {
  backgroundColor: string
  borderColor?: string
  borderInlineStartColor?: string
  textColor: string
  iconColor?: string
  focusColor?: string
}

const tokens: Record<InlineMessageKind, InlineMessageTokensInk> = {
  danger: {
    backgroundColor: inkMessageTokens.messageBgColorError,
    textColor: colors.semanticWhite[100],
    iconColor: colors.semanticWhite[100],
    focusColor: colors.semanticWhite[100],
  },
  information: {
    backgroundColor: inkMessageTokens.messageBgColorSubtle,
    textColor: colors.semanticNeutral[90],
    iconColor: colors.semanticNeutral.fade[65],
    focusColor: colors.semanticNeutral[90],
  },
  success: {
    backgroundColor: inkMessageTokens.messageBgColorSuccess,
    textColor: colors.semanticWhite[100],
    iconColor: colors.semanticWhite[100],
    focusColor: colors.semanticWhite[100],
  },
  warning: {
    backgroundColor: inkMessageTokens.messageBgColorWarning,
    textColor: colors.semanticNeutral[90],
    iconColor: colors.semanticNeutral[90],
    focusColor: colors.semanticNeutral[90],
  },
  emphasis: {
    backgroundColor: inkMessageTokens.messageBgColorEmphasis,
    textColor: colors.semanticWhite[100],
    focusColor: colors.semanticWhite[100],
  },
}

export default tokens
