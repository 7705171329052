import { color } from './primitives'

export const bgColorCanvasDocument = color.warmGray[20]
export const bgColorCanvasPage = color.warmGray[10]
export const bgColorDefault = color.semanticWhite[100]
export const bgColorError = color.semanticCoolRed[60]
export const bgColorInverse = color.semanticNeutral[100]
export const bgColorSuccess = color.semanticGreen[60]

export const inkBackgroundTokens = {
  bgColorCanvasDocument,
  bgColorCanvasPage,
  bgColorDefault,
  bgColorError,
  bgColorInverse,
  bgColorSuccess,
} as const
