import { inkBarTokens, inkBorderTokens } from '@ds/tokens'
import colors from '../colors'

const tokens = {
  defaultTrackColor: inkBarTokens.barTrackColorDefault,
  defaultFillColor: inkBarTokens.barFillColorDefault,
  subtleFillColor: inkBarTokens.barFillColorSubtle,
  highFillColor: inkBarTokens.barFillColorSuccess,
  mediumFillColor: inkBarTokens.barFillColorWarning,
  lowFillColor: inkBarTokens.barFillColorError,
  borderRadius: inkBorderTokens.borderRadiusDefault,
  fillHeight: '8px',
  trackHeight: '2px',

  dark: {
    defaultTrackColor: colors.semanticWhite.fade[35],
    defaultFillColor: colors.semanticBlue[60],
    subtleFillColor: colors.semanticWhite[100],
  },
} as const

export default tokens
