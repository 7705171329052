import { CreateFocusCss } from '../../../../styles'
import type { Tokens } from '../../../../theming/types'

export default (config: { tokens: Tokens }) => {
  const { tokens } = config
  return {
    wrap: {
      display: 'block',
    },

    link: {
      alignItems: 'center',
      display: 'flex',
      marginInlineEnd: '24px',
      textDecoration: 'none',

      ...CreateFocusCss({
        outlineStyle: 'solid',
        outlineWidth: '2px',
        outlineOffset: '4px',
      }),
    },

    image: {
      width: '84px',
    },

    text: {
      borderInlineStart: '1px solid',
      fontFamily: tokens.font.heading,
      height: '28px',
      lineHeight: '28px',
      marginInlineStart: '10px',
      paddingInlineStart: '10px',
      whiteSpace: 'nowrap',

      ...CreateFocusCss(
        {
          textDecoration: 'inherit',
        },
        (psuedoClass) => `a${psuedoClass} &`,
      ),
    },
  } as const
}
