import { color, size } from './primitives'

export const badgeAlertBorderWidth = size[20]
export const badgeAlertBorderColorDefault = color.white
export const badgeAlertBgColorSubtle = color.gray10
export const badgeAlertBgColorEmphasis = color.persianRed
export const badgeStatusBgColorSubtle = color.gray2
export const badgeStatusBgColorEmphasis = color.black
export const badgeStatusBgColorSuccess = color.mantisGreen
export const badgeStatusBgColorWarning = color.sunglowYellowDark2
export const badgeStatusBgColorAlert = color.persianRed

export const oliveBadgeTokens = {
  badgeAlertBorderWidth,
  badgeAlertBorderColorDefault,
  badgeAlertBgColorSubtle,
  badgeAlertBgColorEmphasis,
  badgeStatusBgColorSubtle,
  badgeStatusBgColorEmphasis,
  badgeStatusBgColorSuccess,
  badgeStatusBgColorWarning,
  badgeStatusBgColorAlert,
} as const
