import { color, size } from './primitives'

export const badgeAlertBorderWidth = size[20]

export const badgeAlertBgColorEmphasis = color.semanticCoolRed[60]
export const badgeAlertBgColorSubtle = color.semanticNeutral[80]
export const badgeAlertBorderColorDefault = color.semanticWhite[100]
export const badgeStatusBgColorAlert = color.semanticCoolRed[60]
export const badgeStatusBgColorEmphasis = color.semanticNeutral[100]
export const badgeStatusBgColorSubtle = color.semanticNeutral[10]
export const badgeStatusBgColorSuccess = color.semanticGreen[60]
export const badgeStatusBgColorWarning = color.extendedYellow[60]

export const inkBadgeTokens = {
  badgeAlertBorderWidth,
  badgeAlertBgColorEmphasis,
  badgeAlertBgColorSubtle,
  badgeAlertBorderColorDefault,
  badgeStatusBgColorAlert,
  badgeStatusBgColorEmphasis,
  badgeStatusBgColorSubtle,
  badgeStatusBgColorSuccess,
  badgeStatusBgColorWarning,
} as const
