import { shadow, color } from './primitives'

export const elevationLow = shadow[1]
export const elevationMedium = shadow[4]
export const elevationHigh = shadow[4]
export const elevationHighest = shadow[32]
export const elevationDrag = shadow[1]
export const elevationContainerScrollTop = 'transparent'
export const elevationContainerScrollBottom = color.gray3

export const oliveElevationTokens = {
  elevationLow,
  elevationMedium,
  elevationHigh,
  elevationHighest,
  elevationDrag,
  elevationContainerScrollTop,
  elevationContainerScrollBottom,
} as const
