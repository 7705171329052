import colors from '../../../theming/docusign-themes/ink/colors'
import type { HeaderProps } from '..'

export default (config: { props: Partial<HeaderProps> }) => {
  const { props } = config
  return {
    default: {
      backgroundColor: props.dark
        ? colors.semanticNeutral[100]
        : colors.semanticWhite[100],
    },

    wrap: {
      borderBottom: '1px solid',
      borderColor: colors.semanticNeutral.fade[10],
      color: props.dark
        ? colors.semanticWhite[100]
        : colors.semanticNeutral.fade[90],
      height: '64px',
      paddingInline: props.fullWidth ? '8px 16px' : '4px',
    },
  } as const
}
