import { color } from './primitives'

export const messageBgColorDefault = color.semanticNeutral[100]
export const messageBgColorEmphasis = color.semanticNeutral[100]
export const messageBgColorError = color.semanticCoolRed[60]
export const messageBgColorPromo = color.semanticBlue[60]
export const messageBgColorSubtle = color.semanticNeutral[20]
export const messageBgColorSuccess = color.semanticGreen[60]
export const messageBgColorWarning = color.extendedYellow[20]
export const messageBorderColorDefault = color.semanticNeutral[100]
export const messageBorderColorPromo = color.semanticBlue[60]

export const inkMessageTokens = {
  messageBgColorEmphasis,
  messageBgColorError,
  messageBgColorPromo,
  messageBgColorSubtle,
  messageBgColorSuccess,
  messageBgColorWarning,
  messageBorderColorDefault,
  messageBorderColorPromo,
} as const
