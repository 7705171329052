import { color, font } from './primitives'

export const fontColorDefault = color.black
export const fontColorInverse = color.white
export const fontColorSubtle = color.gray9
export const fontColorSubtleInverse = color.gray2
export const fontColorDisabled = color.gray7
export const fontColorLink = color.funBlue
export const fontColorLinkSubtle = color.funBlue
export const fontColorLinkHover = color.funBlueDark
export const fontColorLinkActive = color.funBlueDark
export const fontColorLinkVisited = color.funBlue
export const fontColorLinkVisitedSubtle = color.funBlue
export const fontColorSuccess = color.mantisGreen
export const fontColorError = color.persianRed
export const fontColorActive = color.funBlue

export const fontDetailXS = {
  fontFamily: font.fontFamily.helvetica,
  fontSize: font.fontSize[12],
  fontWeight: font.fontWeight[400],
  lineHeight: font.lineHeight[18],
} as const

export const fontDetailS = {
  fontFamily: font.fontFamily.helvetica,
  fontSize: font.fontSize[12],
  fontWeight: font.fontWeight[400],
  lineHeight: font.lineHeight[18],
} as const

export const fontBodyM = {
  fontFamily: font.fontFamily.helvetica,
  fontSize: font.fontSize[13],
  fontWeight: font.fontWeight[400],
  lineHeight: font.lineHeight[18],
} as const

export const fontBodyL = {
  fontFamily: font.fontFamily.helvetica,
  fontSize: font.fontSize[13],
  fontWeight: font.fontWeight[400],
  lineHeight: font.lineHeight[18],
} as const

export const fontBodyXL = {
  fontFamily: font.fontFamily.helvetica,
  fontSize: font.fontSize[13],
  fontWeight: font.fontWeight[400],
  lineHeight: font.lineHeight[18],
} as const

export const fontHeadingXXS = {
  fontFamily: font.fontFamily.helvetica,
  fontSize: font.fontSize[13],
  fontWeight: font.fontWeight[600],
  lineHeight: font.lineHeight[18],
} as const

export const fontHeadingXS = {
  fontFamily: font.fontFamily.helvetica,
  fontSize: font.fontSize[13],
  fontWeight: font.fontWeight[600],
  lineHeight: font.lineHeight[18],
} as const

export const fontHeadingS = {
  fontFamily: font.fontFamily.maven,
  fontSize: font.fontSize[13],
  fontWeight: font.fontWeight[600],
  lineHeight: font.lineHeight[18],
} as const

export const fontHeadingM = {
  fontFamily: font.fontFamily.maven,
  fontSize: font.fontSize[15],
  fontWeight: font.fontWeight[600],
  lineHeight: font.lineHeight[20],
} as const

export const fontHeadingL = {
  fontFamily: font.fontFamily.maven,
  fontSize: font.fontSize[18],
  fontWeight: font.fontWeight[600],
  lineHeight: font.lineHeight[24],
} as const

export const fontHeadingXL = {
  fontFamily: font.fontFamily.maven,
  fontSize: font.fontSize[22],
  fontWeight: font.fontWeight[600],
  lineHeight: font.lineHeight[28],
} as const

export const fontDisplayXS = {
  fontFamily: font.fontFamily.helvetica,
  fontSize: font.fontSize[35],
  fontWeight: font.fontWeight[600],
  lineHeight: font.lineHeight[40],
} as const

export const fontDisplayS = {
  fontFamily: font.fontFamily.helvetica,
  fontSize: font.fontSize[35],
  fontWeight: font.fontWeight[400],
  lineHeight: font.lineHeight[40],
} as const

export const fontDisplayM = {
  fontFamily: font.fontFamily.maven,
  fontSize: font.fontSize[35],
  fontWeight: font.fontWeight[600],
  lineHeight: font.lineHeight[40],
} as const

export const fontDisplayL = {
  fontFamily: font.fontFamily.maven,
  fontSize: font.fontSize[35],
  fontWeight: font.fontWeight[600],
  lineHeight: font.lineHeight[40],
} as const

export const fontButton = {
  fontFamily: font.fontFamily.helvetica,
  fontSize: font.fontSize[12],
  fontWeight: font.fontWeight[600],
  lineHeight: font.lineHeight[18],
} as const

export const fontTab = {
  fontFamily: font.fontFamily.maven,
  fontSize: font.fontSize[14],
  fontWeight: font.fontWeight[600],
  lineHeight: font.lineHeight.small,
} as const

export const fontLink = {
  fontFamily: font.fontFamily.helvetica,
  fontSize: font.fontSize[13],
  fontWeight: font.fontWeight[400],
  lineHeight: font.lineHeight.small,
  textDecoration: 'underline',
} as const

export const fontBadge = {
  fontFamily: font.fontFamily.helvetica,
  fontSize: font.fontSize[11],
  fontWeight: font.fontWeight[600],
  lineHeight: font.lineHeight[16],
} as const

export const fontSectionHeadline = {
  fontFamily: font.fontFamily.helvetica,
  fontSize: font.fontSize[12],
  fontWeight: font.fontWeight[600],
  lineHeight: font.lineHeight.large,
} as const

export const fontTimestamp = {
  fontFamily: font.fontFamily.helvetica,
  fontSize: font.fontSize[12],
  fontWeight: font.fontWeight[600],
  lineHeight: font.lineHeight.large,
} as const

export const fontElementLabel = {
  fontFamily: font.fontFamily.helvetica,
  fontSize: font.fontSize[13],
  fontWeight: font.fontWeight[600],
  lineHeight: font.lineHeight[18],
} as const

export const fontBreadcrumb = {
  fontSize: font.fontSize[12],
  fontWeight: font.fontWeight[400],
  lineHeight: 'normal',
} as const

export const oliveFontTokens = {
  fontColorDefault: color.black,
  fontColorInverse: color.white,
  fontColorSubtle: color.gray9,
  fontColorSubtleInverse: color.gray2,
  fontColorDisabled: color.gray7,
  fontColorLink: color.funBlue,
  fontColorLinkSubtle: color.funBlue,
  fontColorLinkHover: color.funBlueDark,
  fontColorLinkActive: color.funBlueDark,
  fontColorLinkVisited: color.funBlue,
  fontColorLinkVisitedSubtle: color.funBlue,
  fontColorSuccess: color.mantisGreen,
  fontColorError: color.persianRed,
  fontColorActive: color.funBlue,
  fontDetailXS,
  fontDetailS,
  fontBodyM,
  fontBodyL,
  fontBodyXL,
  fontHeadingXXS,
  fontHeadingXS,
  fontHeadingS,
  fontHeadingM,
  fontHeadingL,
  fontHeadingXL,
  fontDisplayXS,
  fontDisplayS,
  fontDisplayM,
  fontDisplayL,
  fontButton,
  fontTab,
  fontLink,
  fontBadge,
  fontSectionHeadline,
  fontTimestamp,
  fontElementLabel,
  fontBreadcrumb,
} as const
