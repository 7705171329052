import type { Tokens } from '../../types'

export default (tokens: Tokens) =>
  ({
    html: {
      ...tokens.typography.bodyMedium,
    },

    body: {
      background: '#ffffff',
      color: tokens.text.defaultColor,
      fontFamily: tokens.font.body,
    },
  }) as const
