import type { HeaderContextValue } from '../../HeaderContext'
import { CreateFocusCss } from '../../../../styles'
import { breakpoints } from '../../../../utilities'
import { Tokens } from '../../../../theming/types'
import colors from '../../../../theming/docusign-themes/ink/colors'

export default (config: { props: HeaderContextValue; tokens: Tokens }) => {
  const { props, tokens } = config
  return {
    default: {
      wrap: {
        color: props.dark
          ? colors.semanticWhite.fade[70]
          : colors.semanticNeutral.fade[65],
        fontFamily: tokens.font.body,
        fontSize: '16px',
        fontWeight: 600,
        height: '64px',
        letterSpacing: 'normal',
        textTransform: 'initial',
        paddingInline: '24px',

        [breakpoints.lessThan(breakpoints.Sizes.ExtraSmall)]: {
          paddingInline: '12px',
        },

        '&::after': {
          bottom: 0,
          height: '3px',
          width: '100%',
        },

        '&:hover, &:active, &[aria-expanded="true"]': {
          backgroundColor: props.dark
            ? colors.semanticWhite.fade[25]
            : colors.semanticNeutral.fade[5],
          color: props.dark
            ? colors.semanticWhite[100]
            : colors.semanticNeutral.fade[90],

          '&::after': {
            backgroundColor: 'transparent',
          },

          svg: {
            color: props.dark
              ? colors.semanticWhite[100]
              : colors.semanticNeutral.fade[90],
          },
        },

        ...CreateFocusCss({
          outlineColor: props.dark
            ? colors.semanticWhite[100]
            : colors.semanticNeutral.fade[65],
        }),
      },

      icon: {
        'svg, img': {
          width: '20px',
          height: '20px',
        },
      },

      badge: {
        insetInlineEnd: '15px',
        top: '6px',
      },
    },

    selected: {
      wrap: {
        color: props.dark
          ? colors.semanticWhite[100]
          : colors.semanticNeutral.fade[90],

        '&::after': {
          backgroundColor: props.dark
            ? colors.semanticWhite[100]
            : colors.semanticNeutral[100],
        },

        '&:hover, &:active, &[aria-expanded="true"]': {
          color: props.dark
            ? colors.semanticWhite[100]
            : colors.semanticNeutral.fade[90],

          '&::after': {
            backgroundColor: props.dark
              ? colors.semanticWhite[100]
              : colors.semanticNeutral[100],
          },
        },
      },
    },

    isIconOnly: {
      badge: {
        insetInlineEnd: '7px',
      },
    },

    underlineOnHover: {
      wrap: {
        color: props.dark
          ? colors.semanticWhite[100]
          : colors.semanticNeutral.fade[65],

        '&:hover, &:active, &[aria-expanded="true"]': {
          backgroundColor: props.dark
            ? colors.semanticWhite[100]
            : colors.semanticNeutral.fade[5],
          color: props.dark
            ? colors.semanticWhite[100]
            : colors.semanticNeutral.fade[90],

          svg: {
            color: props.dark
              ? colors.semanticWhite[100]
              : colors.semanticNeutral.fade[90],
          },

          '&::after': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
  } as const
}
