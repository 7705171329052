import { color, shadow } from './primitives'

export const elevationLow = shadow[4]
export const elevationMedium = shadow[8]
export const elevationHigh = shadow[16]
export const elevationHighest = shadow[32]
export const elevationDrag = shadow[4]
export const elevationContainerScrollBottom = color.semanticNeutral.fade[15]
export const elevationContainerScrollTop = color.semanticNeutral.fade[0]

export const inkElevationTokens = {
  elevationLow,
  elevationMedium,
  elevationHigh,
  elevationHighest,
  elevationDrag,
  elevationContainerScrollBottom,
  elevationContainerScrollTop,
} as const
