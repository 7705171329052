import { isTouchScreen } from '../../../styles'
import colors from '../../../theming/docusign-themes/ink/colors'

const TOAST_WRAP = {
  backgroundColor: colors.semanticNeutral[100],
  border: `1px solid ${colors.semanticNeutral[50]}`,
  color: colors.semanticWhite[100],
} as const

export default () =>
  ({
    default: {
      wrap: {
        borderRadius: '4px',
        maxWidth: '440px',
        minWidth: '144px',
        padding: '24px',
        width: 'auto',
      },

      action: {
        marginBlock: '8px 0',
        marginInline: '-16px 0',
      },

      startElement: {
        marginInlineEnd: '16px',
      },

      closeButton: {
        marginBlock: '-8px',
        marginInline: '24px -8px',

        [isTouchScreen]: {
          marginBlock: '-12px',
          marginInline: '24px -12px',
        },
      },
    },

    information: {
      wrap: TOAST_WRAP,
      icon: {
        color: colors.semanticWhite[100], // iconColorDefault dark mode
      },
    },

    warning: {
      wrap: TOAST_WRAP,
      icon: {
        color: colors.extendedYellow[20], // iconColorWarning dark mode
      },
    },

    success: {
      wrap: TOAST_WRAP,
      icon: {
        color: colors.semanticGreen[50], // iconColorSuccess dark mode
      },
    },

    danger: {
      wrap: TOAST_WRAP,
      icon: {
        color: colors.semanticCoolRed[50], // iconColorError dark mode
      },
    },

    loading: {
      wrap: TOAST_WRAP,

      spinner: {
        marginTop: '4px',
      },
    },

    motionVariants: {
      toastWrap: {
        initial: {
          opacity: 0,
        },

        enter: (toastHeight: number) => ({
          y: [toastHeight, 0],
          opacity: 1,
          transition: {
            duration: 0.25,
          },
        }),

        exit: {
          opacity: 0,
          transition: {
            when: 'beforeChildren',
            duration: 0.25,
            ease: 'harmonize',
          },
        },
      },

      toastInner: {
        exit: (toastHeight: number) => ({
          height: [toastHeight, 0],
          padding: [24, 0],
          margin: [16, 0],
          transition: {
            duration: 0.25,
          },
        }),
      },
    },
  }) as const
