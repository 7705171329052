import { inkMessageTokens } from '@ds/tokens'
import type { BannerKind } from '../../../../components/Banner'
import colors from '../colors'

const warningTokens = {
  backgroundColor: inkMessageTokens.messageBgColorWarning,
  textColor: colors.semanticNeutral[90],
  iconColor: colors.semanticNeutral[90],
} as const

export interface BannerTokensInk {
  backgroundColor: string
  borderColor?: string
  textColor: string
  iconColor?: string
  focusColor?: string
}

const tokens: Record<BannerKind, BannerTokensInk> = {
  danger: {
    backgroundColor: inkMessageTokens.messageBgColorError,
    textColor: colors.semanticWhite[100],
    iconColor: colors.semanticWhite[100],
    focusColor: colors.semanticWhite[100],
  },
  information: {
    backgroundColor: inkMessageTokens.messageBgColorEmphasis,
    borderColor: colors.semanticWhite.fade[35],
    textColor: colors.semanticWhite[100],
    iconColor: colors.semanticWhite[100],
    focusColor: colors.semanticWhite[100],
  },
  neutral: {
    backgroundColor: inkMessageTokens.messageBgColorEmphasis,
    borderColor: colors.semanticWhite.fade[35],
    textColor: colors.semanticWhite[100],
    focusColor: colors.semanticWhite[100],
  },
  success: {
    backgroundColor: inkMessageTokens.messageBgColorSuccess,
    textColor: colors.semanticWhite[100],
    iconColor: colors.semanticWhite[100],
    focusColor: colors.semanticWhite[100],
  },
  promo: {
    backgroundColor: inkMessageTokens.messageBgColorPromo,
    textColor: colors.semanticWhite[100],
    focusColor: colors.semanticWhite[100],
  },
  warning: warningTokens,
  subtle: {
    backgroundColor: inkMessageTokens.messageBgColorSubtle,
    borderColor: colors.semanticNeutral.fade[15],
    textColor: colors.semanticNeutral[90],
  },
  // use default kind value for unsupported values
  edit: warningTokens,
  view: warningTokens,
}

export default tokens
