import { oliveButtonTokens } from '@ds/tokens'

const tokens = {
  defaultBackgroundColor: 'transparent',
  defaultTextColor: '#666666',
  defaultHoverBackgroundColor: oliveButtonTokens.secondary.hoverBackgroundColor,
  defaultActiveBackgroundColor:
    oliveButtonTokens.secondary.activeBackgroundColor,

  iconColor: oliveButtonTokens.secondary.iconColor,

  selectedBackgroundColor: '#e9e9e9',
  selectedHoverBackgroundColor:
    oliveButtonTokens.secondary.hoverBackgroundColor,
  selectedActiveBackgroundColor: undefined,
  selectedTextColor: '#666666',

  defaultHeight: oliveButtonTokens.defaultSize,
  mobileHeight: oliveButtonTokens.mobileSize,
} as const

export default tokens
