import colors from '../../../theming/docusign-themes/olive/colors'

const borderColor = {
  danger: colors.persianRed_alpha27,
  information: colors.funBlue_alpha27,
  success: colors.mantisGreen_alpha27,
  warning: colors.sunglowYellowDark_alpha43,
} as const

export default () => {
  return {
    default: {
      wrap: {
        borderInlineStart: `4px solid ${colors.gray4}`,
      },
    },

    danger: {
      wrap: {
        background: colors.persianRedPale2,
        borderColor: borderColor.danger,
        borderInlineStartColor: colors.persianRed,
        color: colors.persianRedDark,
      },
    },

    information: {
      wrap: {
        background: colors.funBluePale2,
        borderColor: borderColor.information,
        borderInlineStartColor: colors.funBlue,
        color: colors.black,
      },
      icon: {
        color: colors.funBlue,
      },
    },

    success: {
      wrap: {
        background: colors.mantisGreenPale2,
        borderColor: borderColor.success,
        borderInlineStartColor: colors.mantisGreen,
        color: colors.black,
      },
    },

    warning: {
      wrap: {
        background: colors.sunglowYellowPale2,
        borderColor: borderColor.warning,
        borderInlineStartColor: colors.sunglowYellowDark3,
        color: colors.black,
      },
      icon: {
        color: colors.sunglowYellowDark3,
      },
    },

    loading: {
      wrap: {
        background: colors.funBluePale2,
        borderColor: borderColor.information,
        borderInlineStartColor: colors.funBlue,
        color: colors.black,
      },
    },
  }
}
