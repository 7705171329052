import { inkFormTokens } from '@ds/tokens'

const tokens = {
  size: '20px',

  defaultHoverShadowColor: 'rgba(25, 24, 35, 0.10)',
  defaultActiveShadowColor: 'rgba(25, 24, 35, 0.20)',

  selectedFillColor: inkFormTokens.formControlBgColorSelected,
  selectedHoverFillColor: inkFormTokens.formControlBgColorHover,
  selectedActiveFillColor: inkFormTokens.formControlBgColorActive,
} as const

export default tokens
