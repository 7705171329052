import { color, font } from './primitives'

export const fontColorActive = color.semanticBlue[60]
export const fontColorDefault = color.semanticNeutral.fade[90]
export const fontColorDisabled = color.semanticNeutral.fade[25]
export const fontColorError = color.semanticCoolRed[60]
export const fontColorInverse = color.semanticWhite[100]
export const fontColorLink = color.semanticBlue[60]
export const fontColorLinkActive = color.semanticBlue[80]
export const fontColorLinkHover = color.semanticBlue[70]
export const fontColorLinkSubtle = color.semanticNeutral.fade[90]
export const fontColorLinkVisited = color.semanticBlue[100]
export const fontColorLinkVisitedSubtle = color.semanticNeutral.fade[65]
export const fontColorSubtle = color.semanticNeutral.fade[65]
export const fontColorSubtleInverse = color.semanticWhite.fade[70]
export const fontColorSuccess = color.semanticGreen[60]

export const fontAvatarGroup = {
  fontFamily: font.fontFamily,
  fontSize: font.fontSize[10],
  fontWeight: font.fontWeight[600],
  lineHeight: font.lineHeight.large,
} as const

export const fontBadge = {
  fontFamily: font.fontFamily,
  fontSize: font.fontSize[10],
  fontWeight: font.fontWeight[600],
  lineHeight: font.lineHeight.large,
  letterSpacing: '0.2px',
} as const

export const fontBodyM = {
  fontFamily: font.fontFamily,
  fontSize: font.fontSize[30],
  fontWeight: font.fontWeight[400],
  lineHeight: font.lineHeight.large,
} as const

export const fontBodyL = {
  fontFamily: font.fontFamily,
  fontSize: font.fontSize[50],
  fontWeight: font.fontWeight[400],
  lineHeight: font.lineHeight.large,
} as const

export const fontBodyXL = {
  fontFamily: font.fontFamily,
  fontSize: font.fontSize[60],
  fontWeight: font.fontWeight[400],
  lineHeight: font.lineHeight.large,
} as const

export const fontBreadcrumb = {
  fontSize: font.fontSize[30],
  fontWeight: font.fontWeight[400],
  lineHeight: 'normal',
} as const

export const fontBreadcrumbActive = {
  fontSize: font.fontSize[30],
  fontWeight: font.fontWeight[500],
  lineHeight: 'normal',
} as const

export const fontButton = {
  fontFamily: font.fontFamily,
  fontSize: font.fontSize[30],
  fontWeight: font.fontWeight[500],
  lineHeight: font.lineHeight.large,
} as const

export const fontDetailXS = {
  fontFamily: font.fontFamily,
  fontSize: font.fontSize[5],
  fontWeight: font.fontWeight[400],
  lineHeight: font.lineHeight.large,
} as const

export const fontDetailS = {
  fontFamily: font.fontFamily,
  fontSize: font.fontSize[10],
  fontWeight: font.fontWeight[400],
  lineHeight: font.lineHeight.large,
} as const

export const fontDisplayXS = {
  fontFamily: font.fontFamily,
  fontSize: font.fontSize[90],
  fontWeight: font.fontWeight[600],
  lineHeight: font.lineHeight.small,
} as const

export const fontDisplayS = {
  fontFamily: font.fontFamily,
  fontSize: font.fontSize[100],
  fontWeight: font.fontWeight[600],
  lineHeight: font.lineHeight.small,
} as const

export const fontDisplayM = {
  fontFamily: font.fontFamily,
  fontSize: font.fontSize[110],
  fontWeight: font.fontWeight[600],
  lineHeight: font.lineHeight.small,
} as const

export const fontDisplayL = {
  fontFamily: font.fontFamily,
  fontSize: font.fontSize[120],
  fontWeight: font.fontWeight[600],
  lineHeight: font.lineHeight.small,
}
export const fontDisplayXL = {
  fontFamily: font.fontFamily,
  fontSize: font.fontSize[130],
  fontWeight: font.fontWeight[600],
  lineHeight: font.lineHeight.small,
} as const

export const fontElementLabel = {
  fontFamily: font.fontFamily,
  fontSize: font.fontSize[30],
  fontWeight: font.fontWeight[500],
  lineHeight: font.lineHeight.large,
} as const

export const fontElementLabelEmphasis = {
  fontFamily: font.fontFamily,
  fontSize: font.fontSize[30],
  fontWeight: font.fontWeight[700],
  lineHeight: font.lineHeight.large,
} as const

export const fontHeadingXXS = {
  fontFamily: font.fontFamily,
  fontSize: font.fontSize[30],
  fontWeight: font.fontWeight[600],
  lineHeight: font.lineHeight.small,
} as const

export const fontHeadingXS = {
  fontFamily: font.fontFamily,
  fontSize: font.fontSize[50],
  fontWeight: font.fontWeight[600],
  lineHeight: font.lineHeight.small,
} as const

export const fontHeadingS = {
  fontFamily: font.fontFamily,
  fontSize: font.fontSize[60],
  fontWeight: font.fontWeight[600],
  lineHeight: font.lineHeight.small,
} as const

export const fontHeadingM = {
  fontFamily: font.fontFamily,
  fontSize: font.fontSize[80],
  fontWeight: font.fontWeight[600],
  lineHeight: font.lineHeight.small,
} as const

export const fontItemSelected = {
  fontFamily: font.fontFamily,
  fontSize: font.fontSize[30],
  fontWeight: font.fontWeight[600],
  lineHeight: font.lineHeight.large,
} as const

export const fontLink = {
  fontFamily: font.fontFamily,
  fontSize: font.fontSize[30],
  fontWeight: font.fontWeight[400],
  lineHeight: font.lineHeight.large,
  textDecoration: 'underline',
} as const

export const fontSectionHeadline = {
  fontFamily: font.fontFamily,
  fontSize: font.fontSize[10],
  fontWeight: font.fontWeight[600],
  lineHeight: font.lineHeight.large,
} as const

export const fontTab = {
  fontFamily: font.fontFamily,
  fontSize: font.fontSize[30],
  fontWeight: font.fontWeight[500],
  lineHeight: font.lineHeight.large,
} as const

export const fontTimestamp = {
  fontFamily: font.fontFamily,
  fontSize: font.fontSize[10],
  fontWeight: font.fontWeight[600],
  lineHeight: font.lineHeight.large,
  letterSpacing: '0.2px',
} as const

export const inkFontColorTokens = {
  fontColorActive,
  fontColorDefault,
  fontColorDisabled,
  fontColorError,
  fontColorInverse,
  fontColorLink,
  fontColorLinkActive,
  fontColorLinkHover,
  fontColorLinkSubtle,
  fontColorLinkVisited,
  fontColorLinkVisitedSubtle,
  fontColorSubtle,
  fontColorSubtleInverse,
  fontColorSuccess,
} as const

export const inkFontTokens = {
  ...inkFontColorTokens,
  fontAvatarGroup,
  fontBadge,
  fontBodyM,
  fontBodyL,
  fontBodyXL,
  fontBreadcrumb,
  fontBreadcrumbActive,
  fontButton,
  fontDetailXS,
  fontDetailS,
  fontDisplayXS,
  fontDisplayS,
  fontDisplayM,
  fontDisplayL,
  fontDisplayXL,
  fontElementLabel,
  fontElementLabelEmphasis,
  fontHeadingXXS,
  fontHeadingXS,
  fontHeadingS,
  fontHeadingM,
  fontItemSelected,
  fontLink,
  fontSectionHeadline,
  fontTab,
  fontTimestamp,
} as const
