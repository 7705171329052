import { CreateFocusCss } from '../../../../styles'
import colors from '../../../../theming/docusign-themes/ink/colors'
import type { HeaderContextValue } from '../../HeaderContext'

export default (config: { props: HeaderContextValue }) => {
  const { props } = config
  return {
    wrap: {
      height: '64px',
    },

    button: {
      '&:hover, &:active, &[aria-expanded="true"]': {
        backgroundColor: props.dark
          ? colors.semanticWhite.fade[25]
          : colors.semanticNeutral.fade[5],
      },

      ...CreateFocusCss({
        outlineColor: props.dark
          ? colors.semanticWhite[100]
          : colors.semanticNeutral.fade[65],
      }),
    },
  } as const
}
