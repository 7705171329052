import { inkIconTokens } from '@ds/tokens'

const tokens = {
  smallSize: '20px',
  defaultSize: '24px',

  defaultColor: inkIconTokens.iconColorDefault,
  disabledColor: inkIconTokens.iconColorDisabled,
  emphasisColor: inkIconTokens.iconColorEmphasis,
  errorColor: inkIconTokens.iconColorError,
  warningColor: inkIconTokens.iconColorWarning,
  successColor: inkIconTokens.iconColorSuccess,

  hoverColor: 'rgba(25, 24, 35, 0.65)',
} as const

export default tokens
