import { color, radius, size } from './primitives'

export const borderColorSubtle = color.gray3
export const borderColorDefault = color.gray3
export const borderColorEmphasis = color.gray3
export const borderColorSubtleInverse = color.gray8
export const borderWidthXS = size[10]
export const borderWidthS = size[20]
export const borderRadiusDefault = radius[10]
export const borderRadiusAlternate = radius[20]

export const oliveBorderTokens = {
  borderColorSubtle,
  borderColorDefault,
  borderColorEmphasis,
  borderColorSubtleInverse,
  borderWidthXS,
  borderWidthS,
  borderRadiusDefault,
  borderRadiusAlternate,
} as const
