import { CreateFocusCss } from '../../../../styles'
import { Tokens } from '../../../../theming/types'

export default (config: { tokens: Tokens }) => {
  const { tokens } = config
  return {
    wrap: {
      '& a, & button': {
        minWidth: 'inherit',

        ...CreateFocusCss({
          ...tokens.focus.outer,
          outlineColor: '#fff',
        }),
      },
    },
  } as const
}
