import { color } from './primitives'

export const bgColorDefault = color.white
export const bgColorInverse = color.black
export const bgColorCanvasPage = color.gray6
export const bgColorCanvasDocument = color.gray3
export const bgColorSuccess = color.mantisGreenLight
export const bgColorError = color.persianRedLight

export const oliveBackgroundTokens = {
  bgColorDefault,
  bgColorInverse,
  bgColorCanvasPage,
  bgColorCanvasDocument,
  bgColorSuccess,
  bgColorError,
} as const
