const font = {
  fontSize: {
    12: '12px',
    13: '13px',
    14: '14px',
    15: '15px',
    18: '18px',
    22: '22px',
    35: '35px',
    40: '40px',
  },
  fontWeight: {
    400: 400,
    600: 600,
    700: 700,
  },
  lineHeight: {
    small: '1',
    large: '1.5',
    18: '18px',
    20: '20px',
    24: '24px',
    28: '28px',
    40: '40px',
  },
  fontFamily: {
    helvetica: '"Helvetica Neue",HelveticaNeue,Helvetica,Arial,sans-serif',
    maven:
      '"Maven Pro","Helvetica Neue","HelveticaNeue",Helvetica,Arial,sans-serif',
  },
} as const

export default font
