import { Tokens } from '../../../theming/types'
import { styles } from '../../../utilities'
import { zIndexes } from '../../../variables'

export default (config: { tokens: Tokens }) => {
  const { tokens } = config
  return {
    default: {
      wrap: {
        ...tokens.typography.bodyMedium,
        alignItems: 'flex-start',
        border: '1px solid',
        borderRadius: '2px',
        boxShadow: tokens.elevation.medium,
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '16px',
        overflow: 'hidden',
        padding: '16px',
        position: 'relative',
        width: '100%',
        zIndex: zIndexes.ToastMessage,
      },

      icon: {
        height: tokens.icon.defaultSize,
      },

      content: {
        overflowWrap: 'break-word',
        wordBreak: 'break-word',
        wordWrap: 'break-word',

        '& a': {
          color: 'inherit',
          textDecoration: 'underline',
        },
      },

      action: {
        marginBlock: '4px 0',
        marginInline: '-12px 0',
      },

      startElement: {
        flex: '0 0 auto',
        marginInlineEnd: '14px',
      },

      closeButton: {
        flex: '0 0 auto',
        marginBlock: '-6px',
        marginInline: '8px -6px',
      },

      body: {
        alignSelf: 'center',
        flex: '1 1 0%',
        minWidth: 0,
      },
    },

    visuallyHidden: {
      wrap: styles.visuallyHidden,
    },

    danger: {
      icon: {
        color: tokens.icon.errorColor,
      },
    },

    success: {
      icon: {
        color: tokens.icon.successColor,
      },
    },

    loading: {
      spinner: {
        display: 'flex',
      },
    },

    hidden: styles.visuallyHidden,

    motionVariants: {
      toastWrap: {
        initial: {
          opacity: 0,
        },

        enter: (toastHeight: number) => ({
          y: [-toastHeight, 0],
          opacity: 1,
          transition: {
            duration: 0.35,
          },
        }),

        exit: {
          opacity: 0,
          transition: {
            when: 'beforeChildren',
            duration: 0.25,
            ease: 'opacity',
          },
        },
      },

      toastInner: {
        exit: (toastHeight: number) => ({
          height: [toastHeight, 0],
          padding: [16, 0],
          margin: [16, 0],
          transition: {
            duration: 0.25,
            ease: 'linear',
          },
        }),
      },
    },
  } as const
}
