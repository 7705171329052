import * as React from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from '@ds/comp-private'
import { dataProps } from '@ds/react-utils'
import { SkipNav } from '../../internal/components/SkipNav'
import { useThemeStyles } from '../../theming'
import type { ConditionalReactElementChildOrArray } from '../../types'
import { HeaderActionItem } from './HeaderActionItem'
import { HeaderActionItemIcon } from './HeaderActionItemIcon'
import { HeaderAvatar } from './HeaderAvatar'
import { HeaderCenter, HeaderCenterProps } from './HeaderCenter'
import { HeaderContext } from './HeaderContext'
import { HeaderLeft, HeaderLeftProps } from './HeaderLeft'
import { HeaderLogo } from './HeaderLogo'
import { HeaderNav } from './HeaderNav'
import { HeaderRight, HeaderRightProps } from './HeaderRight'
import { HeaderTitle } from './HeaderTitle'
import styles from './styles'

const DEFAULT_SKIP_NAV_TEXT = 'Skip to main content'

export interface HeaderProps {
  /**
   * The `children` prop accepts `<Header.Start>`, `<Header.Center>`, and/or `<Header.End>` components.
   */
  children: ConditionalReactElementChildOrArray<
    HeaderLeftProps | HeaderCenterProps | HeaderRightProps
  >
  /**
   * @ignore
   * Apply dark styles to header.
   */
  dark?: boolean
  /**
   * Accepts custom data attributes.
   */
  'data-.*'?: string
  'data-qa'?: string
  /**
   * Forces the Header to fill the full width of its container,
   * for those special cases that require it (see Tagger).
   */
  fullWidth?: boolean
  /**
   * The id of the element representing the page's main content.
   * The element with this id will receive focus when the "skip nav" is clicked.
   *
   * The "skip nav" will not display if a target is not provided here.
   */
  skipNavTarget?: string
  /**
   * The text of the "skip nav" that is displayed when a skipNavTarget is provided.
   */
  skipNavText?: string
}

/**
 * This example only exists for historical reference. Please use [@ds/components/Header](https://artifactory.docusignhq.com/artifactory/docusign-public/ds-components/master/demo/index.html#/header).
 */
export function Header({
  children,
  dark = false,
  'data-qa': dataQa,
  fullWidth = false,
  skipNavTarget,
  skipNavText = DEFAULT_SKIP_NAV_TEXT,
  ...restProps
}: HeaderProps) {
  const sx = useThemeStyles(styles, { dark, fullWidth })
  const translate = useTranslate()

  const contextValue = React.useMemo(() => ({ dark }), [dark])

  const skipNavTextTranslated =
    skipNavText === DEFAULT_SKIP_NAV_TEXT
      ? translate('OLIVE:SKIP_TO_MAIN')
      : skipNavText

  const skipNavTargetId = skipNavTarget && skipNavTarget.replace(/^#+/, '')

  const skipNav = skipNavTargetId && (
    <SkipNav
      data-qa={dataQa && `${dataQa}-skip-nav`}
      text={skipNavTextTranslated}
      target={skipNavTargetId}
    />
  )

  return (
    <HeaderContext.Provider value={contextValue}>
      {skipNav}
      <header {...dataProps(restProps)} css={sx.default} data-qa={dataQa}>
        <div
          data-qa={dataQa && `${dataQa}-wrap`}
          css={sx.wrap}
          className="olv-header olv-ignore-transform"
        >
          {children}
        </div>
      </header>
    </HeaderContext.Provider>
  )
}

Header.propTypes = {
  children: PropTypes.node.isRequired,
  dark: PropTypes.bool,
  'data-.*': PropTypes.string,
  fullWidth: PropTypes.bool,
  skipNavTarget: PropTypes.string,
  skipNavText: PropTypes.string,
}

Header.displayName = 'Header'

/**
 * @deprecated Use `Header.Start` instead.
 */
Header.Left = HeaderLeft
Header.Start = HeaderLeft
Header.Center = HeaderCenter
/**
 * @deprecated Use `Header.End` instead.
 */
Header.Right = HeaderRight
Header.End = HeaderRight
Header.ActionItem = HeaderActionItem
Header.ActionItemIcon = HeaderActionItemIcon
Header.Avatar = HeaderAvatar
Header.Logo = HeaderLogo
Header.Nav = HeaderNav
Header.Title = HeaderTitle
