import { Tokens } from '../../../theming/types'

import { CreateFocusCss } from '../../../styles'
import colors from '../../../theming/docusign-themes/olive/colors'

export default (config: { tokens: Tokens }) => {
  const { tokens } = config
  return {
    default: {
      wrap: {
        display: 'inline-block',
        position: 'relative',

        '& > a:hover': {
          boxShadow: `0 0 0 4px ${tokens.selectionControl.defaultHoverShadowColor}`,
        },

        '& > a:active': {
          boxShadow: `0 0 0 4px ${tokens.selectionControl.defaultActiveShadowColor} !important`,
        },

        '& > a': CreateFocusCss(tokens.focus.outer),
      },

      avatar: {
        alignItems: 'center',
        backgroundColor: colors.periwinkleLight,
        border: 'none',
        borderRadius: '50%',
        color: tokens.text.defaultColor,
        display: 'inline-flex',
        flexShrink: 0,
        fontFamily: tokens.font.body,
        fontSize: 'inherit',
        fontWeight: 600,
        justifyContent: 'center',
        lineHeight: 1.5,
        overflow: 'hidden',
        padding: 0,
        position: 'relative',
        transition: 'box-shadow 0.2s ease',
        textAlign: 'center',
        textDecoration: 'none',
        textTransform: 'uppercase',
        verticalAlign: 'middle',
        wordBreak: 'keep-all',
      },

      badge: {
        lineHeight: '0px',
        bottom: '-2px',
        position: 'absolute',
        insetInlineEnd: '-2px',
      },

      shadow: {
        boxShadow: tokens.elevation.low,
      },
    },

    image: {
      avatar: {
        img: {
          maxWidth: '100%',
        },
      },
    },

    placeholder: {
      avatar: {
        backgroundColor: colors.periwinkleDark2,
        color: colors.white,

        '& > svg': {
          borderRadius: '50%',
          fill: colors.white,
          height: '100%',
          overflow: 'hidden',
          width: '100%',
        },
      },
    },

    withColorIndex: {
      avatar: {
        color: tokens.avatar.withColorIndexTextColor,
        '&:hover': {
          color: tokens.avatar.withColorIndexTextColor,
        },
      },
    },

    sizeXxlarge: {
      avatar: {
        fontSize: tokens.avatar.xxlargeFontSize,
        height: '96px',
        width: '96px',
      },
    },

    sizeXlarge: {
      avatar: {
        fontSize: tokens.avatar.xlargeFontSize,
        height: '72px',
        width: '72px',
      },
    },

    sizeLarge: {
      avatar: {
        fontSize: tokens.avatar.largeFontSize,
        height: '48px',
        width: '48px',
      },
    },

    sizeMedium: {
      avatar: {
        fontSize: tokens.avatar.mediumFontSize,
        height: '36px',
        width: '36px',
      },
    },

    sizeSmall: {
      avatar: {
        fontSize: tokens.avatar.smallFontSize,
        height: '28px',
        width: '28px',
      },
    },

    indexedColor: tokens.indexedColor,
  } as const
}
