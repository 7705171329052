import { color, radius, size } from './primitives'

export const borderColorDefault = color.semanticNeutral.fade[65]
export const borderColorEmphasis = color.semanticNeutral.fade[90]
export const borderColorSubtle = color.semanticNeutral.fade[15]
export const borderColorSubtleInverse = color.semanticWhite.fade[35]
export const borderRadiusDefault = radius[10]
export const borderRadiusAlternate = radius[20]
export const borderWidthXS = size[10]
export const borderWidthS = size[20]

export const inkBorderTokens = {
  borderColorDefault,
  borderColorEmphasis,
  borderColorSubtle,
  borderColorSubtleInverse,
  borderRadiusDefault,
  borderRadiusAlternate,
  borderWidthXS,
  borderWidthS,
} as const
