import color from '../primitives/color'

export const main = {
  activeBackgroundColor: color.semanticBlue[80],
  activeTextColor: undefined,
  buttonGroupSeparatorColor: color.semanticNeutral.fade[90],
  defaultBackgroundColor: color.semanticBlue[60],
  defaultBorderColor: undefined,
  defaultTextColor: color.semanticWhite[100],
  disabledBackgroundColor: color.semanticBlue[60],
  disabledBorderColor: undefined,
  disabledOpacity: 0.25,
  disabledTextColor: undefined,
  hoverBackgroundColor: color.semanticBlue[70],
  hoverTextColor: undefined,
  iconColor: undefined,
  iconHoverColor: undefined,
} as const

export const primary = {
  activeBackgroundColor: color.semanticBlue[80],
  activeTextColor: undefined,
  buttonGroupSeparatorColor: color.semanticNeutral.fade[90],
  defaultBackgroundColor: color.semanticBlue[60],
  defaultBorderColor: undefined,
  defaultTextColor: color.semanticWhite[100],
  disabledBackgroundColor: color.semanticBlue[60],
  disabledBorderColor: undefined,
  disabledOpacity: 0.25,
  disabledTextColor: undefined,
  hoverBackgroundColor: color.semanticBlue[70],
  hoverTextColor: undefined,
  iconColor: undefined,
  iconHoverColor: undefined,
} as const

export const secondary = {
  activeBackgroundColor: color.semanticNeutral.fade[10],
  activeTextColor: undefined,
  buttonGroupSeparatorColor: color.semanticNeutral.fade[65],
  defaultBackgroundColor: 'transparent',
  defaultBorderColor: color.semanticNeutral.fade[65],
  defaultTextColor: color.semanticNeutral.fade[90],
  disabledBackgroundColor: 'transparent',
  disabledBorderColor: undefined,
  disabledOpacity: 0.25,
  disabledTextColor: undefined,
  hoverBackgroundColor: color.semanticNeutral.fade[5],
  hoverTextColor: undefined,
  iconColor: color.semanticNeutral.fade[65],
  iconHoverColor: color.semanticNeutral.fade[65],
} as const

export const tertiary = {
  activeBackgroundColor: color.semanticNeutral.fade[10],
  activeTextColor: undefined,
  buttonGroupSeparatorColor: undefined,
  defaultBackgroundColor: 'transparent',
  defaultBorderColor: undefined,
  defaultTextColor: color.semanticNeutral.fade[90],
  disabledBackgroundColor: 'transparent',
  disabledBorderColor: undefined,
  disabledOpacity: 0.25,
  disabledTextColor: undefined,
  hoverBackgroundColor: color.semanticNeutral.fade[5],
  hoverTextColor: undefined,
  iconColor: color.semanticNeutral.fade[65],
  iconHoverColor: color.semanticNeutral.fade[65],
} as const

export const danger = {
  activeBackgroundColor: color.semanticCoolRed[80],
  activeTextColor: undefined,
  buttonGroupSeparatorColor: color.semanticCoolRed[100],
  defaultBackgroundColor: color.semanticCoolRed[60],
  defaultBorderColor: undefined,
  defaultTextColor: color.semanticWhite[100],
  disabledBackgroundColor: color.semanticCoolRed[60],
  disabledBorderColor: undefined,
  disabledOpacity: 0.25,
  disabledTextColor: undefined,
  hoverBackgroundColor: color.semanticCoolRed[70],
  hoverTextColor: undefined,
  iconColor: undefined,
  iconHoverColor: undefined,
} as const

export const inkButtonTokens = {
  main,
  primary,
  secondary,
  tertiary,
  danger,

  invertedBackgroundColor: color.semanticWhite[100],
  invertedTextColor: color.semanticNeutral[100],

  defaultSize: '40px',
  mobileSize: '48px',
} as const
