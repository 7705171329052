import React from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from '@ds/comp-private'
import { dataProps, onProps } from '@ds/react-utils'
import { InkAvatarPlaceholder, OliveAvatarPlaceholder } from '@olive/svg'
import { ConditionalTag } from '../../internal/components/ConditionalTag'
import { CreateFocusCss } from '../../styles'
import { CustomPropTypes } from '../../support'
import { useIsInk, useThemeStyles } from '../../theming'
import type {
  ConditionalTagRef,
  ConditionalTagMouseEventHandler,
  ConditionalTagEventListenerProps,
} from '../../types'
import { variant } from '../../utilities'
import { AnchorTarget, anchorTargets } from '../../variables'
import { AvatarBadge, AvatarBadgeProps } from './AvatarBadge'
import styles from './styles'

/**
 * Calculate the start margin of the Avatar sizes in the AvatarGroup. 1/3 of width.
 */
const calculateMarginInlineStartCssValue = (width: string) => {
  const OFFSET_GROUP = 3
  const offset = parseInt(width.replace(/\D/g, ''), 10) / OFFSET_GROUP
  const marginInlineStart = `-${offset}px`
  return marginInlineStart
}

export const TOP_OF_GROUP_STACK = 50
const ABOVE_GROUP_STACK = 51

export const avatarSizes = [
  'xxlarge',
  'xlarge',
  'large',
  'medium',
  'small',
] as const

export type AvatarSize = 'xxlarge' | 'xlarge' | 'large' | 'medium' | 'small'

export interface AvatarProps extends ConditionalTagEventListenerProps {
  /**
   * Optional aria-label custom text to identify the Avatar. Aria-label defaults to a
   * translatable string of "Default profile image" or "Icon displaying your initials"
   * if this prop is omitted.
   *
   * @deprecated Use `accessibilityText` instead.
   */
  accessibilityLabel?: string
  /**
   * The text to present to assistive devices in order to identify the Avatar.
   *
   * In most cases, this should be set to: "User: [name]", where name describes the person who the Avatar is of.
   * Example: "User: John Smith". Add additional context as appropriate, such as "Comment by [name]".
   *
   * If the Avatar is used on an Account or Profile page where the user is able to modify it,
   * this should be set to "Icon displaying your initials" or "Your uploaded profile image"
   * (whichever is appropriate, so the user knows what their Avatar currently is).
   *
   * If the Avatar performs an action or opens to a new page, ensure its purpose is described.
   *
   * If the Avatar is purely decorative (such as if the user's name is adjacent) and is not a button or link,
   * pass an empty string.
   */
  accessibilityText?: string
  /**
   * Adds a badge to the Avatar.
   * The value should be a Avatar.Badge components
   */
  badge?: React.ReactElement<AvatarBadgeProps>
  /**
   * The zero-based index of the Avatar when displayed as a member of a collection.
   *
   * The effect of this prop will be to provide a distinguishing border color (and background
   * color when the 'required' prop is TRUE) for Avatar.
   *
   * Note: the Avatar, SignTag and InitialTag components all use this prop and will display
   * the same color given the same value; a common use case would be for displaying sets
   * of color-grouped Avatar, SignTag and InitialTag components for an individual signing
   * recipient.
   */
  colorIndex?: number
  /**
   * Do not add an aria-label attribute to the Avatar element.
   * @deprecated Use `accessibilityText=""` instead.
   */
  hideAriaLabel?: boolean
  /**
   * URL for navigating. If a URL is supplied it renders as an anchor element, if not,
   * a span element.
   */
  href?: string
  /**
   * The zero-based index of the Avatar when displayed as a child of
   * an AvatarGroup (where the index indicates its position within the
   * AvatarGroup, from the start).
   *
   * The effect of this prop will be to provide a z-index that stacks
   * the overlapped Avatars within the AvatarGroup (starting one on top) and
   * displays any hovered Avatar on the top of that stack.
   *
   * NOTE: do not supply a value for this prop if the Avatar component is
   * not a child of an AvatarGroup
   */
  groupIndex?: number
  /**
   * The URL of an image to display for the Avatar.
   *
   * note: if an image is given here, any value provided to
   * the 'initials' prop will be ignored
   */
  image?: string
  /**
   * The initials to display for the Avatar (only the first two
   * characters of the provided string will be used).
   *
   * note: if neither 'initials' nor 'image' is given a value, then
   * the Avatar will display a placeholder image
   */
  initials?: string
  /**
   * A React ref to assign to the HTML node representing the Avatar element.
   */
  forwardedRef?: ConditionalTagRef
  onMouseEnter?: ConditionalTagMouseEventHandler
  onMouseLeave?: ConditionalTagMouseEventHandler
  /**
   * The relationship of the linked URL of an anchor as space-separated link types.
   *
   * Reference: https://developer.mozilla.org/en-US/docs/Web/HTML/Link_types
   */
  rel?: string
  /**
   * The size of the Avatar.
   */
  size?: AvatarSize
  /**
   * The HTML href target (if an href is _not_ provided, this is ignored).
   * When target="_blank" use rel="noreferrer" or rel="noopener" to avoid the vulnerability.
   */
  target?: AnchorTarget
  /**
   * Text for the Tooltip when displayed inside an `AvatarGroup`.
   */
  tooltipText?: string
  /**
   * Displays a drop shadow around the Avatar.
   */
  shadow?: boolean
  /**
   * Accepts custom data attributes.
   */
  'data-.*'?: string
  'data-qa'?: string
}

/**
 * Avatars are used to represent a unique entity, either a person or group.
 */
export function Avatar({
  accessibilityLabel,
  accessibilityText,
  badge,
  colorIndex,
  href,
  forwardedRef,
  groupIndex,
  hideAriaLabel = false,
  image,
  initials,
  onClick,
  rel,
  size = 'medium',
  shadow = false,
  target,
  tooltipText,
  ...restProps
}: AvatarProps) {
  const translate = useTranslate()

  const sx = useThemeStyles(styles)
  const indexedColor = sx.indexedColor(colorIndex)

  const ariaLabelText = () => {
    if (accessibilityText || accessibilityLabel) {
      return accessibilityText || accessibilityLabel
    }

    if (image) {
      return `${translate('OLIVE:UPLOADED_PROFILE_IMAGE')}`
    }

    if (initials) {
      return `${translate('OLIVE:ICON_DISPLAYING_INITIALS')}`
    }

    return `${translate('OLIVE:DEFAULT_PROFILE_IMAGE')}`
  }

  const ariaAttributes = () => {
    if (!hideAriaLabel && !href && !image && !onClick && !tooltipText) {
      return {
        'aria-label': ariaLabelText(),
        role: 'img',
      }
    }
    return undefined
  }

  const initialNode =
    initials &&
    (!hideAriaLabel && (href || onClick || tooltipText) ? (
      <span aria-label={ariaLabelText()} role="img">
        {initials.substring(0, 2)}
      </span>
    ) : (
      initials.substring(0, 2)
    ))

  const imageNode = <img src={image} alt={ariaLabelText()} />

  const placeholderNode = useIsInk() ? (
    <InkAvatarPlaceholder />
  ) : (
    <OliveAvatarPlaceholder />
  )

  const hasColorIndex = colorIndex !== undefined
  const hasGroupIndex = groupIndex !== undefined

  const colorIndexStyles = hasColorIndex && [
    sx.withColorIndex.avatar,
    { backgroundColor: indexedColor.solid },
  ]

  const placeholderStyles = !(image || initials) && sx.placeholder.avatar

  let groupIndexStyles
  if (hasGroupIndex) {
    const avatarWidth = size && sx[variant('size', size)].avatar.width
    const marginInlineStart = calculateMarginInlineStartCssValue(
      avatarWidth as string,
    )

    groupIndexStyles = {
      zIndex: TOP_OF_GROUP_STACK - groupIndex,
      marginInlineStart,

      '&:hover': {
        zIndex: ABOVE_GROUP_STACK,
      },

      ...CreateFocusCss({ zIndex: ABOVE_GROUP_STACK }),
    }
  }

  const avatarStyles = [
    sx.default.avatar,
    image && sx.image.avatar,
    size && sx[variant('size', size)].avatar,
    colorIndexStyles,
    placeholderStyles,
    groupIndexStyles,
    shadow && sx.default.shadow,
  ]

  const badgeElement = badge && (
    <span css={sx.default.badge}>{React.cloneElement(badge, { size })}</span>
  )

  return (
    <span css={sx.default.wrap}>
      <ConditionalTag
        {...dataProps(restProps)}
        {...onProps(restProps)}
        {...ariaAttributes()}
        css={avatarStyles}
        className="olv-avatar olv-ignore-transform"
        forwardedRef={forwardedRef}
        href={href}
        onClick={onClick}
        forceElement={tooltipText ? 'button' : undefined}
        rel={rel}
        target={target}
      >
        {image ? imageNode : initialNode || placeholderNode}
      </ConditionalTag>
      {badgeElement}
    </span>
  )
}

Avatar.sizes = avatarSizes

Avatar.targets = anchorTargets

Avatar.propTypes = {
  accessibilityLabel: PropTypes.string,
  accessibilityText: PropTypes.string,
  badge: PropTypes.node,
  colorIndex: PropTypes.number,
  'data-.*': PropTypes.string,
  forwardedRef: CustomPropTypes.ReactRef,
  groupIndex: PropTypes.number,
  hideAriaLabel: PropTypes.bool,
  href: PropTypes.string,
  image: PropTypes.string,
  initials: PropTypes.string,
  'on[A-Z].*': PropTypes.func,
  rel: PropTypes.string,
  shadow: PropTypes.bool,
  size: PropTypes.oneOf(avatarSizes),
  target: PropTypes.oneOf(anchorTargets),
}

Avatar.displayName = 'Avatar'

Avatar.Badge = AvatarBadge
