import { color, size } from './primitives'

export const formElementTextColorRequired = color.persianRed
export const formElementTextColorDisabled = color.gray7
export const formElementInputBgColorDefault = color.white
export const formElementInputBgColorDisabled = color.gray2
export const formElementInputBgColorRead = color.gray1
export const formElementInputBgColorError = color.persianRed
export const formElementInputBorderWidth = size[10]
export const formElementInputBorderWidthActive = size[20]
export const formElementInputBorderColor = color.gray4
export const formElementInputBorderColorHover = color.gray8
export const formElementInputBorderColorActive = color.funBlue
export const formElementInputBorderColorError = color.persianRed
export const formControlBgColorDefault = color.white
export const formControlBgColorHover = color.funBlueDark2
export const formControlBgColorActive = color.funBlueDark2
export const formControlBgColorError = color.persianRed
export const formControlBgColorSelected = color.funBlue
export const formControlBgColorSelectedActive = color.funBlueDark2
export const formControlBorderColorDefault = color.gray8
export const formControlBorderColorHover = color.gray8
export const formControlBorderColorActive = color.black
export const formControlBorderColorError = color.persianRed
export const formControlBorderColorSelected = color.funBlue
export const formControlBorderColorSelectedHover = color.funBlueDark
export const formControlBorderColorSelectedActive = color.DocuSignBlue
export const formControlHighlightBgColorDefault = color.gray3
export const formControlThumbBgColorDefault = color.black
export const formControlThumbBgColorHover = color.funBlue
export const formControlThumbBgColorActive = color.DocuSignBlue
export const formControlBorderColorUnselectedActive = color.black

export const oliveFormTokens = {
  formElementTextColorRequired,
  formElementTextColorDisabled,
  formElementInputBgColorDefault,
  formElementInputBgColorDisabled,
  formElementInputBgColorRead,
  formElementInputBgColorError,
  formElementInputBorderWidth,
  formElementInputBorderWidthActive,
  formElementInputBorderColor,
  formElementInputBorderColorHover,
  formElementInputBorderColorActive,
  formElementInputBorderColorError,
  formControlBgColorDefault,
  formControlBgColorHover,
  formControlBgColorActive,
  formControlBgColorError,
  formControlBgColorSelected,
  formControlBgColorSelectedActive,
  formControlBorderColorDefault,
  formControlBorderColorHover,
  formControlBorderColorActive,
  formControlBorderColorError,
  formControlBorderColorSelected,
  formControlBorderColorSelectedHover,
  formControlBorderColorSelectedActive,
  formControlHighlightBgColorDefault,
  formControlThumbBgColorDefault,
  formControlThumbBgColorHover,
  formControlThumbBgColorActive,
  formControlBorderColorUnselectedActive,
} as const
