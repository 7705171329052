import color from '../primitives/color'

export const main = {
  defaultBackgroundColor: color.sunglowYellow,
  defaultBorderColor: undefined,
  defaultTextColor: color.black,
  hoverBackgroundColor: color.sunglowYellowDark,
  hoverTextColor: undefined,
  activeBackgroundColor: color.sunglowYellowDark2,
  activeTextColor: undefined,
  disabledBackgroundColor: color.sunglowYellowPale,
  disabledBorderColor: undefined,
  disabledTextColor: color.gray7,
  disabledOpacity: undefined,
  iconColor: color.gray10,
  iconHoverColor: undefined,
  buttonGroupSeparatorColor: color.gray9,
} as const

export const primary = {
  defaultBackgroundColor: color.funBlue,
  defaultBorderColor: undefined,
  defaultTextColor: color.white,
  hoverBackgroundColor: color.funBlueDark,
  hoverTextColor: undefined,
  activeBackgroundColor: color.funBlueDark2,
  activeTextColor: undefined,
  disabledBackgroundColor: color.funBluePale,
  disabledBorderColor: undefined,
  disabledTextColor: color.gray7,
  disabledOpacity: undefined,
  iconColor: undefined,
  iconHoverColor: undefined,
  buttonGroupSeparatorColor: color.gray5,
} as const

export const secondary = {
  defaultBackgroundColor: color.gray1,
  defaultBorderColor: color.gray5,
  defaultTextColor: color.black,
  hoverBackgroundColor: color.gray3,
  hoverTextColor: undefined,
  activeBackgroundColor: color.gray4,
  activeTextColor: undefined,
  disabledBackgroundColor: color.gray1,
  disabledBorderColor: color.gray3,
  disabledTextColor: color.gray7,
  disabledOpacity: undefined,
  iconColor: color.gray9,
  iconHoverColor: undefined,
  buttonGroupSeparatorColor: color.gray8,
} as const

export const tertiary = {
  defaultBackgroundColor: 'transparent',
  defaultBorderColor: undefined,
  defaultTextColor: color.black,
  hoverBackgroundColor: 'transparent',
  hoverTextColor: color.funBlue,
  activeBackgroundColor: 'transparent',
  activeTextColor: color.funBlueDark,
  disabledBackgroundColor: 'transparent',
  disabledBorderColor: undefined,
  disabledTextColor: color.gray6,
  disabledOpacity: undefined,
  iconColor: color.gray8,
  iconHoverColor: color.funBlue,
  buttonGroupSeparatorColor: undefined,
} as const

export const danger = {
  defaultBackgroundColor: color.persianRed,
  defaultBorderColor: undefined,
  defaultTextColor: color.white,
  hoverBackgroundColor: color.persianRedDark,
  hoverTextColor: undefined,
  activeBackgroundColor: color.persianRedDark2,
  activeTextColor: undefined,
  disabledBackgroundColor: color.persianRedPale,
  disabledBorderColor: undefined,
  disabledTextColor: color.gray7,
  disabledOpacity: undefined,
  iconColor: undefined,
  iconHoverColor: undefined,
  buttonGroupSeparatorColor: color.white,
} as const

export const oliveButtonTokens = {
  main,
  primary,
  secondary,
  tertiary,
  danger,

  invertedBackgroundColor: color.white,
  invertedTextColor: color.black,

  defaultSize: '28px',
  mobileSize: '28px',
} as const
