import * as React from 'react'
import PropTypes from 'prop-types'
import { Global, css } from '@emotion/react'
import { ThemeProvider } from '@ds/theme-context'
import type { CustomBranding, DocuSignTheme } from '../../types'
import { FontFaceDeclarations } from '../FontFaceDeclarations'
import { NormalizeCss } from '../NormalizeCss'
import { ConditionalCobranding } from './ConditionalCobranding'
import globalStyles from './globalStyles'

export interface ThemeProps {
  /**
   * @ignore @internal Subject to change. Do not use.
   *
   * An AccountBranding object that provides a string with a hex color value
   * (e.g. '#FF00FF') for the following keys:
   *
   * - buttonMainBackground
   * - buttonMainText
   * - buttonPrimaryBackground
   * - buttonPrimaryText
   * - headerBackground
   * - headerText
   *
   * The provided colors will be used to apply custom styles to eligible components,
   * and will take priority over any other styles provided (default or otherwise,
   * including any provided to the 'styles' prop of this component).
   *
   * In the case of nested instances of the Theme component, styles generated
   * here from the provided AccountBranding object will be merged with those generated
   * by ancestor Theme components (with those closest to the styled component
   * taking precedence), with the resulting styles taking priority over any other
   * styles provided (as detailed above).
   */
  accountBranding?: CustomBranding
  /**
   * The content to be displayed and to receive the styles and icons provided by this component (for those eligible components).
   */
  children: React.ReactNode
  /**
   * Accepts custom data attributes.
   */
  'data-.*'?: string
  'data-qa'?: string
  /**
   * Which DocuSign theme to use. Currently supports Olive and Ink themes.
   */
  docuSignTheme: DocuSignTheme
  /**
   * Inject @font-face declarations based on the provided theme.
   */
  enableFontFaceDeclarations?: boolean
  /**
   * Inject global styles (such as font-family and font-size) for the provided theme.
   * For multiple themes in the same app, only enable this for the primary theme.
   */
  enableGlobalCss?: boolean
  /**
   * This prop is useful for `Theme` components with an "Ink" docuSign theme nested inside of
   * apps using an "Olive" docuSignTheme. If true, will add "Ink" fontSize, lineHeight, fontFamily
   * and fontWeight to a div wrapping its children
   */
  enableInkContainerStyles?: boolean
  /**
   * Inject normalized styles. Setting this to false means you'll need to add the normalization of "box-sizing: border-box" for components in @ds/ui to style correctly
   */
  enableNormalizeCss?: boolean
}

/**
 * Use of the library requires that you pass your application content as a child to the top-level `<Theme />` component with a DocuSign theme.
 */
export function Theme({
  accountBranding,
  children,
  'data-qa': dataQa,
  docuSignTheme,
  enableFontFaceDeclarations = false,
  enableGlobalCss = false,
  enableInkContainerStyles = false,
  enableNormalizeCss = true,
}: ThemeProps) {
  function renderChildren() {
    if (enableInkContainerStyles) {
      const containerStyles = {
        fontSize: '16px',
        lineHeight: 1.5,
        fontWeight: 400,
        fontFamily:
          '"DS Indigo",DSIndigo,"Neue Haas Grotesk",NeueHaasGrotesk,Helvetica,Arial,sans-serif',
        '*': {
          textTransform: 'unset',
        } as const,
      }

      return (
        <div
          data-qa={dataQa && `${dataQa}-ink-container`}
          css={containerStyles}
        >
          {children}
        </div>
      )
    }

    return children
  }

  return (
    <ThemeProvider docuSignTheme={docuSignTheme}>
      <ConditionalCobranding accountBranding={accountBranding}>
        {enableGlobalCss && (
          <Global styles={css(globalStyles(docuSignTheme.tokens))} />
        )}
        {enableNormalizeCss && <NormalizeCss />}
        {enableFontFaceDeclarations && (
          <FontFaceDeclarations fonts={docuSignTheme.fonts} />
        )}
        {renderChildren()}
      </ConditionalCobranding>
    </ThemeProvider>
  )
}

Theme.propTypes = {
  accountBranding: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
  docuSignTheme: PropTypes.shape({}).isRequired,
  enableFontFaceDeclarations: PropTypes.bool,
  enableGlobalCss: PropTypes.bool,
  enableInkContainerStyles: PropTypes.bool,
  enableNormalizeCss: PropTypes.bool,
}
