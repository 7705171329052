import { color } from './primitives'

export const iconColorDefault = color.semanticNeutral.fade[65]
export const iconColorDisabled = color.semanticNeutral.fade[25]
export const iconColorEmphasis = color.semanticNeutral.fade[90]
export const iconColorError = color.semanticCoolRed[60]
export const iconColorSuccess = color.semanticGreen[60]
export const iconColorWarning = color.extendedYellow[60]

export const inkIconTokens = {
  iconColorDefault,
  iconColorDisabled,
  iconColorEmphasis,
  iconColorError,
  iconColorSuccess,
  iconColorWarning,
} as const
