import colors from '../../../../theming/docusign-themes/ink/colors'
import type { HeaderContextValue } from '../../HeaderContext'
import type { HeaderTitleProps } from '..'

function getBorderColor(isDark: boolean | undefined) {
  return isDark
    ? colors.semanticWhite.fade[45]
    : colors.semanticNeutral.fade[45]
}

export default (config: {
  props: Partial<HeaderTitleProps> & HeaderContextValue
}) => {
  const { props } = config
  return {
    borderColor: props.precededByHeaderAction
      ? getBorderColor(props.dark)
      : undefined,
    color: props.dark
      ? colors.semanticWhite[100]
      : colors.semanticNeutral.fade[65],
    paddingInlineEnd: '15px',
  } as const
}
