import { color } from './primitives'

export const messageBgColordefault = color.funBluePale2
export const messageBgColorPromo = color.funBlue
export const messageBgColorEmphasis = color.funBluePale2
export const messageBgColorSubtle = color.funBluePale2
export const messageBgColorSuccess = color.mantisGreenPale2
export const messageBgColorWarning = color.sunglowYellowPale2
export const messageBgColorError = color.persianRedPale2
export const messageBorderColorDefault = color.funBlue
export const messageBorderColorPromo = color.funBlue

export const oliveMessageTokens = {
  messageBgColorPromo,
  messageBgColorEmphasis,
  messageBgColorSubtle,
  messageBgColorSuccess,
  messageBgColorWarning,
  messageBgColorError,
  messageBorderColorDefault,
  messageBorderColorPromo,
} as const
