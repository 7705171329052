import { inkButtonTokens, inkFontTokens } from '@ds/tokens'

const tokens = {
  defaultBackgroundColor: inkButtonTokens.secondary.defaultBackgroundColor,
  defaultTextColor: inkButtonTokens.tertiary.defaultTextColor,
  defaultHoverBackgroundColor: '#f4f4f6',
  defaultActiveBackgroundColor: '#d9d9e0',

  iconColor: inkButtonTokens.tertiary.iconColor,

  selectedBackgroundColor: 'rgba(25, 24, 35, 0.65)',
  selectedHoverBackgroundColor: 'rgba(25, 24, 35, 0.75)',
  selectedActiveBackgroundColor: 'rgba(25, 24, 35, 0.90)',
  selectedTextColor: inkFontTokens.fontColorInverse,

  defaultHeight: inkButtonTokens.defaultSize,
  mobileHeight: inkButtonTokens.mobileSize,
} as const

export default tokens
