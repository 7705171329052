import avatar from './avatar'
import avatarBadge from './avatarBadge'
import avatarGroup from './avatarGroup'
import banner from './banner'
import bar from './bar'
import border from './border'
import button from './button'
import breadcrumb from './breadcrumb'
import elevation from './elevation'
import focus from './focus'
import font from './font'
import form from './form'
import icon from './icon'
import indexedColor from './indexedColor'
import inlineMessage from './inlineMessage'
import link from './link'
import menuItem from './menuItem'
import modal from './modal'
import popover from './popover'
import selectionControl from './selectionControl'
import scrim from './scrim'
import tabs from './tabs'
import text from './text'
import toolbar from './toolbar'
import tooltip from './tooltip'
import typography from './typography'

const tokens = {
  avatar,
  avatarBadge,
  avatarGroup,
  banner,
  bar,
  border,
  breadcrumb,
  button,
  elevation,
  focus,
  font,
  form,
  icon,
  indexedColor,
  inlineMessage,
  link,
  menuItem,
  modal,
  popover,
  selectionControl,
  scrim,
  tabs,
  text,
  toolbar,
  tooltip,
  typography,
} as const

export default tokens
