import { inkButtonTokens } from '@ds/tokens'

const main = {
  defaultBackgroundColor: inkButtonTokens.main.defaultBackgroundColor,
  defaultBorderColor: undefined,
  defaultTextColor: inkButtonTokens.main.defaultTextColor,
  hoverBackgroundColor: inkButtonTokens.main.hoverBackgroundColor,
  hoverTextColor: undefined,
  activeBackgroundColor: inkButtonTokens.main.activeBackgroundColor,
  activeTextColor: undefined,
  disabledBackgroundColor: inkButtonTokens.main.disabledBackgroundColor,
  disabledBorderColor: undefined,
  disabledTextColor: undefined,
  disabledOpacity: inkButtonTokens.main.disabledOpacity,
  iconColor: undefined,
  iconHoverColor: undefined,
  buttonGroupSeparatorColor: inkButtonTokens.main.buttonGroupSeparatorColor,
} as const

const primary = {
  defaultBackgroundColor: inkButtonTokens.primary.defaultBackgroundColor,
  defaultBorderColor: undefined,
  defaultTextColor: inkButtonTokens.primary.defaultTextColor,
  hoverBackgroundColor: inkButtonTokens.primary.hoverBackgroundColor,
  hoverTextColor: undefined,
  activeBackgroundColor: inkButtonTokens.primary.activeBackgroundColor,
  activeTextColor: undefined,
  disabledBackgroundColor: inkButtonTokens.primary.disabledBackgroundColor,
  disabledBorderColor: undefined,
  disabledTextColor: undefined,
  disabledOpacity: inkButtonTokens.primary.disabledOpacity,
  iconColor: undefined,
  iconHoverColor: undefined,
  buttonGroupSeparatorColor: inkButtonTokens.primary.buttonGroupSeparatorColor,
} as const

const secondary = {
  defaultBackgroundColor: inkButtonTokens.secondary.defaultBackgroundColor,
  defaultBorderColor: inkButtonTokens.secondary.defaultBorderColor,
  defaultTextColor: inkButtonTokens.secondary.defaultTextColor,
  hoverBackgroundColor: inkButtonTokens.secondary.hoverBackgroundColor,
  hoverTextColor: undefined,
  activeBackgroundColor: inkButtonTokens.secondary.activeBackgroundColor,
  activeTextColor: undefined,
  disabledBackgroundColor: inkButtonTokens.secondary.disabledBackgroundColor,
  disabledBorderColor: undefined,
  disabledTextColor: undefined,
  disabledOpacity: inkButtonTokens.secondary.disabledOpacity,
  iconColor: inkButtonTokens.secondary.iconColor,
  iconHoverColor: inkButtonTokens.secondary.iconHoverColor,
  buttonGroupSeparatorColor:
    inkButtonTokens.secondary.buttonGroupSeparatorColor,
} as const

const tertiary = {
  defaultBackgroundColor: inkButtonTokens.tertiary.defaultBackgroundColor,
  defaultBorderColor: undefined,
  defaultTextColor: inkButtonTokens.tertiary.defaultTextColor,
  hoverBackgroundColor: inkButtonTokens.tertiary.hoverBackgroundColor,
  hoverTextColor: undefined,
  activeBackgroundColor: inkButtonTokens.tertiary.activeBackgroundColor,
  activeTextColor: undefined,
  disabledBackgroundColor: inkButtonTokens.tertiary.disabledBackgroundColor,
  disabledBorderColor: undefined,
  disabledTextColor: undefined,
  disabledOpacity: inkButtonTokens.tertiary.disabledOpacity,
  iconColor: inkButtonTokens.tertiary.iconColor,
  iconHoverColor: inkButtonTokens.tertiary.iconHoverColor,
  buttonGroupSeparatorColor: undefined,
} as const

const danger = {
  defaultBackgroundColor: inkButtonTokens.danger.defaultBackgroundColor,
  defaultBorderColor: undefined,
  defaultTextColor: inkButtonTokens.danger.defaultTextColor,
  hoverBackgroundColor: inkButtonTokens.danger.hoverBackgroundColor,
  hoverTextColor: undefined,
  activeBackgroundColor: inkButtonTokens.danger.activeBackgroundColor,
  activeTextColor: undefined,
  disabledBackgroundColor: inkButtonTokens.danger.disabledBackgroundColor,
  disabledBorderColor: undefined,
  disabledTextColor: undefined,
  disabledOpacity: inkButtonTokens.danger.disabledOpacity,
  iconColor: undefined,
  iconHoverColor: undefined,
  buttonGroupSeparatorColor: inkButtonTokens.danger.buttonGroupSeparatorColor,
} as const

const tokens = {
  main,
  primary,
  secondary,
  tertiary,
  danger,

  invertedBackgroundColor: inkButtonTokens.invertedBackgroundColor,
  invertedTextColor: inkButtonTokens.invertedTextColor,

  defaultSize: '40px',
  mobileSize: '48px',
} as const

export default tokens
