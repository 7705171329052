import { inkBackgroundTokens, inkFontTokens, inkIconTokens } from '@ds/tokens'

const tokens = {
  defaultBackgroundColor: inkBackgroundTokens.bgColorDefault,
  defaultTextColor: inkFontTokens.fontColorSubtle,
  defaultIconColor: inkIconTokens.iconColorDefault,
  hoverActiveBackgroundColor: 'rgba(25, 24, 35, 0.20)',
  hoverBackgroundColor: 'rgba(25, 24, 35, 0.10)',
  hoverTextColor: inkFontTokens.fontColorDefault,
  hoverIconColor: inkIconTokens.iconColorEmphasis,
  disabledTextColor: inkFontTokens.fontColorDisabled,
  disabledIconColor: inkIconTokens.iconColorDisabled,
  indicatorColor: inkFontTokens.fontColorDefault,
  indicatorWidth: '2px',
  selectedTextColor: inkFontTokens.fontColorDefault,

  dark: {
    defaultBackgroundColor: inkFontTokens.fontColorSubtle,
    defaultTextColor: inkFontTokens.fontColorSubtleInverse,
    defaultIconColor: inkFontTokens.fontColorSubtleInverse,
    hoverActiveBackgroundColor: 'rgba(255, 255, 255, 0.35)',
    hoverBackgroundColor: 'rgba(255, 255, 255, 0.25)',
    hoverTextColor: inkFontTokens.fontColorInverse,
    hoverIconColor: inkFontTokens.fontColorInverse,
    disabledTextColor: inkFontTokens.fontColorDisabled,
    disabledIconColor: 'rgba(25, 24, 35, 0.65)',
    indicatorColor: inkFontTokens.fontColorInverse,
    selectedTextColor: inkFontTokens.fontColorInverse,
  },
} as const

export default tokens
