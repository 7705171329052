import { CreateFocusCss } from '../../../../styles'
import colors from '../../../../theming/docusign-themes/ink/colors'
import type { HeaderContextValue } from '../../HeaderContext'

export default (config: { props: HeaderContextValue }) => {
  const { props } = config
  return {
    link: {
      paddingInlineStart: '12px',
      paddingTop: '3px',

      ...CreateFocusCss({
        outlineColor: props.dark
          ? colors.semanticWhite[100]
          : colors.semanticNeutral.fade[65],
        outlineOffset: '2px',
      }),
    },

    image: {
      width: '111px',

      svg: {
        fill: props.dark
          ? colors.semanticWhite[100]
          : colors.semanticBlack[100],
      },
    },

    text: {
      borderColor: props.dark
        ? colors.semanticWhite.fade[45]
        : colors.semanticNeutral.fade[45],
      color: props.dark
        ? colors.semanticWhite[100]
        : colors.semanticNeutral[100],
      fontSize: '16px',
      fontWeight: 500,
      height: '24px',
      lineHeight: '24px',
      marginInlineStart: '12px',
      paddingInlineStart: '12px',
    },
  } as const
}
