import type { Tokens } from '../../../../theming/types'

export default (config: { tokens: Tokens }) => {
  const { tokens } = config
  return {
    default: {
      caret: {
        display: 'inline-flex',
        pointerEvents: 'none',
      },
    },

    disabled: {
      caret: {
        opacity: tokens.form.disabledOpacity,
      },
    },
  } as const
}
