import color from './color'

const shadowColor = color.semanticNeutral.fade[15]

const shadow = {
  1: `0px 1px 0px ${shadowColor}`,
  '1R': `0px -1px 0px ${shadowColor}`,
  4: `0px 4px 8px ${shadowColor}`,
  '4R': `0px -4px 8px ${shadowColor}`,
  8: `0px 8px 16px ${shadowColor}`,
  16: `0px 16px 32px ${shadowColor}`,
  32: `0px 32px 64px ${shadowColor}`,
} as const

export default shadow
