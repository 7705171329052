import { color } from './primitives'

export const iconColorDefault = color.gray8
export const iconColorEmphasis = color.gray8
export const iconColorSuccess = color.mantisGreen
export const iconColorWarning = color.sunglowYellowDark3
export const iconColorError = color.persianRed
export const iconColorDisabled = color.gray5

export const oliveIconTokens = {
  iconColorDefault,
  iconColorEmphasis,
  iconColorSuccess,
  iconColorWarning,
  iconColorError,
  iconColorDisabled,
} as const
