import hexToRgba from 'hex-to-rgba'

const brandColor = {
  white: '#ffffff',
  gray1: '#f9f9f9',
  gray2: '#f4f4f4',
  gray3: '#e9e9e9',
  gray4: '#d9d9d9',
  gray5: '#cccccc',
  gray6: '#a9a9a9',
  gray7: '#999999',
  gray8: '#868686',
  gray9: '#666666',
  gray10: '#3d3d3d',
  black: '#1e1e1e',

  badgeOrange: '#cc4c15',
  badgeTurquoise: '#3d7e8f',

  DocuSignBluePale2: '#e3edf7',
  DocuSignBluePale: '#c9dcf0',
  DocuSignBlueDisabled: '#4f8dce',
  DocuSignBlueLight: '#2875c4',
  DocuSignBlue: '#005cb9',
  DocuSignBlueDark: '#004185',
  DocuSignBlueDark2: '#003368',

  funBluePale2: '#ebf5fc',
  funBluePale: '#d8edfa',
  funBlueLight: '#4186ec',
  funBlue: '#2463d1',
  funBlueDark: '#1951b8',
  funBlueDark2: '#164295',

  mantisGreenPale2: '#e3f2e9',
  mantisGreenPale: '#c6e5d3',
  mantisGreenLight: '#39a364',
  mantisGreen: '#008938',
  mantisGreenDark: '#007831',
  mantisGreenDark2: '#00672a',

  orange: '#ff9980',
  orangeDark: '#ff8567',

  periwinklePale2: '#f6f7fa',
  periwinklePale: '#f0f2f6',
  periwinkleLight: '#e8edf7',
  periwinkle: '#d9e1f1',
  periwinkleDark: '#c7d1e2',
  periwinkleDark2: '#b8bfcc',

  persianRedPale2: '#ffeee9',
  persianRedPale: '#ffe3e1',
  persianRedLight: '#fb3449',
  persianRed: '#d13239',
  persianRedDark: '#b22b31',
  persianRedDark2: '#98252b',

  progressIndicatorInverted: '#85d8eb',

  sunglowYellowPale2: '#fffae5',
  sunglowYellowPale: '#ffefc0',
  sunglowYellowLight: '#ffd72e',
  sunglowYellow: '#ffce00',
  sunglowYellowDark: '#f6b610',
  sunglowYellowDark2: '#e5a305',
  sunglowYellowDark3: '#be8704',

  turquoisePale2: '#edf8fb',
  turquoisePale: '#e1f4f8',
  turquoiseLight: '#9ce2f2',
  turquoise: '#6ac6dc',
  turquoiseDark: '#52b4cd',
  turquoiseDark2: '#4b9aaf',
} as const

const colorWithAlpha = {
  black_alpha50: hexToRgba(brandColor.black, 0.5),
  gray9_alpha90: hexToRgba(brandColor.gray9, 0.9),
  white_alpha75: hexToRgba(brandColor.white, 0.75),

  funBlue_alpha27: hexToRgba(brandColor.funBlue, 0.27),
  mantisGreen_alpha27: hexToRgba(brandColor.mantisGreen, 0.27),
  persianRed_alpha27: hexToRgba(brandColor.persianRed, 0.27),
  sunglowYellowDark_alpha43: hexToRgba(brandColor.sunglowYellowDark, 0.43),
} as const

export default {
  ...brandColor,
  ...colorWithAlpha,
}
