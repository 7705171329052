import { keyframes } from '@emotion/react'
import { CreateButtonCss, CreateFocusCss } from '../../../styles'
import type { Tokens } from '../../../theming/types'
import { styles } from '../../../utilities'
import type { ButtonProps } from '..'

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

export default (config: {
  props: Pick<ButtonProps, 'loading' | 'animate' | 'text'>
  tokens: Tokens
}) => {
  const { props, tokens } = config
  return {
    button: {
      default: {
        ...tokens.typography.button,
        alignItems: 'center',
        borderRadius: '2px',
        borderColor: 'transparent',
        borderStyle: 'solid',
        borderWidth: '1px',
        cursor: props?.loading ? 'progress' : 'pointer',
        display: 'inline-flex',
        justifyContent: props?.animate ? undefined : 'center',
        letterSpacing: '0.66px',
        margin: 0,
        minHeight: tokens.button.defaultSize,
        overflow: 'visible',
        padding: '4px 14px',
        position: 'relative',
        textAlign: 'center',
        textDecoration: 'none',
        textTransform: 'uppercase',
        transitionProperty: 'background-color, border-color, box-shadow, color',
        transitionDuration: '0.1s',
        transitionTimingFunction: 'cubic-bezier(0.33, 0, 0.67, 1)',
        verticalAlign: 'middle',

        '&:lang(ja), &:lang(ko), &:lang(zh)': {
          whiteSpace: 'nowrap',
        },

        '& img': {
          height: '16px',
        },

        '& svg': {
          fill: 'currentColor',
          transition: 'fill 0.2s ease',
        },

        '&:hover': {
          svg: {
            fill: 'currentColor',
          },
        },

        ...CreateFocusCss(tokens.focus.outer),

        '&:disabled, &:disabled:hover': {
          cursor: 'not-allowed',
        },
      },

      disabled: {
        '&:hover': {
          cursor: 'not-allowed',
        },
      },

      groupStart: {
        borderRadius: '2px 0px 0px 2px',
        // Support for logical border-radius is not good. Thus, leave in above fallback.
        borderStartStartRadius: '2px',
        borderStartEndRadius: '0px',
        borderEndEndRadius: '0px',
        borderEndStartRadius: '2px',
        borderInlineEnd: 'none',

        ...CreateFocusCss({
          zIndex: 1,
        }),
      },

      groupCenter: {
        borderRadius: 0,
        borderInlineEnd: 'none',

        ...CreateFocusCss({
          zIndex: 1,
        }),
      },

      groupEnd: {
        borderRadius: '0px 2px 2px 0px',
        borderStartStartRadius: '0px',
        borderStartEndRadius: '2px',
        borderEndEndRadius: '2px',
        borderEndStartRadius: '0px',
        ...CreateFocusCss({
          zIndex: 1,
        }),
      },

      main: CreateButtonCss(tokens.button.main),
      primary: CreateButtonCss(tokens.button.primary),
      secondary: CreateButtonCss(tokens.button.secondary),
      tertiary: CreateButtonCss(tokens.button.tertiary),
      danger: CreateButtonCss(tokens.button.danger),

      inverted: {
        ...CreateFocusCss({
          outlineColor: tokens.button.invertedBackgroundColor,
        }),

        primary: {
          backgroundColor: tokens.button.invertedBackgroundColor,
          borderColor: tokens.button.invertedBackgroundColor,
          color: tokens.button.invertedTextColor,

          '& svg': {
            fill: tokens.button.invertedTextColor,
          },

          '&:hover': {
            backgroundColor: 'transparent',
            borderColor: tokens.button.invertedBackgroundColor,
            color: tokens.button.invertedBackgroundColor,

            '& svg': {
              fill: tokens.button.invertedBackgroundColor,
            },
          },

          '&:active': {
            opacity: 0.7,
          },

          '&:disabled, &:disabled:hover': {
            opacity: 0.3,
          },
        },

        secondary: {
          backgroundColor: 'transparent',
          borderColor: tokens.button.invertedBackgroundColor,
          color: tokens.button.invertedBackgroundColor,

          '& svg': {
            fill: tokens.button.invertedBackgroundColor,
          },

          '&:hover': {
            backgroundColor: tokens.button.invertedBackgroundColor,
            color: tokens.button.invertedTextColor,

            '& svg': {
              fill: tokens.button.secondary.buttonGroupSeparatorColor,
            },
          },

          '&:active': {
            opacity: 0.7,
          },

          '&:disabled, &:disabled:hover': {
            opacity: 0.4,
          },
        },

        tertiary: {
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          color: tokens.button.invertedBackgroundColor,

          '& svg': {
            fill: tokens.button.invertedBackgroundColor,
          },

          '&:hover': {
            borderColor: tokens.button.invertedBackgroundColor,
            color: tokens.button.invertedBackgroundColor,
            transition: `
            background-color 0.2s ease,
            border 0.2s ease,
            box-shadow 0.2s ease,
            color 0.2s ease
          `,

            '& svg': {
              fill: tokens.button.invertedBackgroundColor,
            },
          },

          '&:active, &[aria-expanded="true"]': {
            opacity: 0.7,
          },

          '&:disabled, &:disabled:hover': {
            opacity: 0.4,
          },
        },
      },

      round: {
        borderRadius: '50%',
        boxShadow: tokens.elevation.low,
        height: '48px',
        minHeight: '48px',
        padding: 0,
        width: '48px',

        '&:hover': {
          boxShadow: tokens.elevation.medium,
        },
      },

      fullWidth: {
        width: '100%',
      },

      text: {
        pointerEvents: 'none',
      },
    },

    icon: {
      default: {
        lineHeight: '0.66px',
        pointerEvents: 'none',
      },

      round: {
        margin: 0,
      },

      hideText: {
        margin: 0,
      },
    },

    caret: {
      display: 'inline-flex',
    },

    hideText: {
      padding: 0,
    },

    hidden: styles.visuallyHidden,

    fileInput: {
      input: {
        ...styles.visuallyHidden,

        '&:focus + label': tokens.focus.outer,
      },
    },

    loadingSpinner: {
      animation: `${spin} 1s infinite linear`,
      borderRadius: '100%',
      borderStyle: 'solid',
      borderTopColor: 'transparent',
      borderWidth: '2px',
      display: 'inline-block',
      flexShrink: 0,
      height: '16px',
      marginInline: '4px',
      marginInlineEnd: props.text ? '12px' : undefined,
      verticalAlign: 'middle',
      width: '16px',
    },

    animation: {
      display: 'inline-flex',
      justifyContent: 'flex-start',
      width: '100%',

      '&[data-animating="true"]': {
        justifyContent: 'center',
      },

      inner: {
        alignItems: 'center',
        display: 'inline-flex',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
  } as const
}
