import { color } from './primitives'

export const itemBgColorActive = color.semanticNeutral.fade[20]
export const itemBgColorActiveSubtle = color.semanticNeutral.fade[10]
export const itemBgColorHover = color.semanticNeutral.fade[10]
export const itemBgColorHoverSubtle = color.semanticNeutral.fade[5]
export const itemBgColorSelected = color.semanticNeutral.fade[10]
export const itemBgColorSelectedSubtle = color.semanticNeutral.fade[5]
export const itemBorderColorActive = color.semanticNeutral.fade[90]
export const itemBorderColorHover = color.semanticNeutral.fade[65]

export const inkItemTokens = {
  itemBgColorActive,
  itemBgColorActiveSubtle,
  itemBgColorHover,
  itemBgColorHoverSubtle,
  itemBgColorSelected,
  itemBgColorSelectedSubtle,
  itemBorderColorActive,
  itemBorderColorHover,
} as const
