import { color, size } from './primitives'

export const barWidthXS = size[20]
export const barWidthS = size[30]
export const barWidthM = size[40]

export const barFillColorDefault = color.semanticBlue[60]
export const barFillColorError = color.semanticCoolRed[60]
export const barFillColorSubtle = color.semanticNeutral[100]
export const barFillColorSuccess = color.semanticGreen[60]
export const barFillColorWarning = color.extendedYellow[30]
/** @deprecated Use `barFillColorDefault` instead. */
export const barColorFillDefault = barFillColorDefault
/** @deprecated Use `barFillColorSuccess` instead. */
export const barColorFillHigh = barFillColorSuccess
/** @deprecated Use `barFillColorError` instead. */
export const barColorFillLow = barFillColorError
/** @deprecated Use `barFillColorWarning` instead. */
export const barColorFillMed = barFillColorWarning
/** @deprecated Use `barFillColorSubtle` instead. */
export const barColorFillSubtle = barFillColorSubtle

export const barTrackColorDefault = color.semanticNeutral.fade[50]
export const barTrackColorEmphasis = color.semanticNeutral[100]

export const inkBarTokens = {
  barWidthXS,
  barWidthS,
  barWidthM,
  barFillColorDefault,
  barFillColorError,
  barFillColorSubtle,
  barFillColorSuccess,
  barFillColorWarning,
  /** @deprecated Use `barFillColorDefault` instead. */
  barColorFillDefault,
  /** @deprecated Use `barFillColorSuccess` instead. */
  barColorFillHigh,
  /** @deprecated Use `barFillColorError` instead. */
  barColorFillLow,
  /** @deprecated Use `barFillColorWarning` instead. */
  barColorFillMed,
  /** @deprecated Use `barFillColorSubtle` instead. */
  barColorFillSubtle,
  barTrackColorDefault,
  barTrackColorEmphasis,
} as const
