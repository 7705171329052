import colors from '../../../theming/docusign-themes/ink/colors'

export default () =>
  ({
    default: {
      avatar: {
        backgroundColor: colors.semanticNeutral[30],
      },
    },

    placeholder: {
      avatar: {
        backgroundColor: colors.semanticNeutral[20],
        color: colors.semanticNeutral.fade[90],

        '& > svg': {
          borderRadius: 0,
          fill: colors.semanticNeutral.fade[90],
          height: '50%',
          width: '50%',
        },
      },
    },

    sizeXxlarge: {
      avatar: {
        height: '48px',
        width: '48px',
      },
    },

    sizeXlarge: {
      avatar: {
        height: '48px',
        width: '48px',
      },
    },

    sizeLarge: {
      avatar: {
        height: '48px',
        width: '48px',
      },
    },

    sizeMedium: {
      avatar: {
        height: '40px',
        width: '40px',
      },
    },

    sizeSmall: {
      avatar: {
        height: '32px',
        width: '32px',
      },
    },
  }) as const
