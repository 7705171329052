import { color, size } from './primitives'

export const barWidthXS = size[20]
export const barWidthS = size[30]
export const barWidthM = size[40]

export const barFillColorDefault = color.funBlue
export const barFillColorSubtle = color.black
export const barFillColorSuccess = color.mantisGreen
export const barFillColorWarning = color.sunglowYellow
export const barFillColorError = color.persianRed
/** @deprecated Use `barFillColorDefault` instead. */
export const barColorFillDefault = barFillColorDefault
/** @deprecated Use `barFillColorSubtle` instead. */
export const barColorFillSubtle = barFillColorSubtle
/** @deprecated Use `barFillColorSuccess` instead. */
export const barColorFillHigh = barFillColorSuccess
/** @deprecated Use `barFillColorWarning` instead. */
export const barColorFillMed = barFillColorWarning
/** @deprecated Use `barFillColorError` instead. */
export const barColorFillLow = barFillColorError

export const barTrackColorDefault = color.gray3
export const barTrackColorEmphasis = color.gray6

export const oliveBarTokens = {
  barWidthXS,
  barWidthS,
  barWidthM,
  barFillColorDefault,
  barFillColorSubtle,
  barFillColorSuccess,
  barFillColorWarning,
  barFillColorError,
  /** @deprecated Use `barFillColorDefault` instead. */
  barColorFillDefault,
  /** @deprecated Use `barFillColorSubtle` instead. */
  barColorFillSubtle,
  /** @deprecated Use `barFillColorSuccess` instead. */
  barColorFillHigh,
  /** @deprecated Use `barFillColorWarning` instead. */
  barColorFillMed,
  /** @deprecated Use `barFillColorError` instead. */
  barColorFillLow,
  barTrackColorDefault,
  barTrackColorEmphasis,
} as const
