const HELVETICA_NEUE_FONT_FAMILY =
  '"Helvetica Neue",HelveticaNeue,Helvetica,Arial,sans-serif'
const MAVEN_PRO_FONT_FAMILY =
  '"Maven Pro","Helvetica Neue","HelveticaNeue",Helvetica,Arial,sans-serif'

const tokens = {
  body: HELVETICA_NEUE_FONT_FAMILY,
  heading: MAVEN_PRO_FONT_FAMILY,
} as const

export default tokens
